import React from 'react';
import CustomFormattedDate from '../utils/customFormattedDate';

const GameInfo = (props) => {
  const gameData = props.gameData[0];
  
  return (
    <div className="game_info">
      { (gameData) ? (
        <>
        <div className="font-urbanist cell small-12 large-auto text-black"><strong>{gameData.event_name}</strong></div>
        <div className="font-urbanist cell small-12 large-shrink text-black text-base">{gameData.location} - {CustomFormattedDate(gameData.start_time)} | {gameData.court}</div>    
      
        </>
      ) : ''}
    </div>
  )
}

export default GameInfo
