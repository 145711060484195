import React from 'react';
import RosterData from './rosterData';

const HomeTeam = (props) => {

  return (
    <>
    {/* <div>{ (props.gameData[0]) ? (<h1>{props.gameData[0].home_team}</h1>) : '' }</div> */}
    <RosterData rosterData={props.rosterData} jNumData={props.jNumData} eventId={props.eventId} rosterType={props.rosterType} teamType={'home'}/>
    </>
  );
}

export default HomeTeam