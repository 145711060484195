import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const LogoutPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe(); // Cleanup on component unmount
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('Sign out successfully.');
        if (!redirected) {
          window.location.href = '/login';
          setRedirected(true);
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
    <div className="bg-black" >{ user ? <><button className="font-urbanist text-red-dark text-base my-1 p-1 rounded-lg bg-red" onClick={userSignOut}>Sign Out</button></> : '' }</div>
    </>
  );
};

export default LogoutPage;
