import React from 'react'

export const playerZero = (playerType) => {
  const homePlayerZeroData = {
    "id": "11000",
    "name": "Player Zero",
    "profile_img": process.env.REACT_APP_DEFAULT_IMG_PLACEHOLDER,
    "ast": 0,
    "blk": 0,
    "rbs": 0,
    "stl": 0,
    "fg_att": 0,
    "ft_att": 0,
    "fg_made": 0,
    "ft_made": 0,
    "three_pt_att": 0,
    "pts": 0,
    "fg_percentage": 0,
    "ft_percentage": 0,
    "three_pt": 0,
    "three_pt_percentage": 0,
    "homeaway": "home"
  }
  const awayPlayerZeroData = {
    "id": "11001",
    "name": "Player Zero",
    "profile_img": process.env.REACT_APP_DEFAULT_IMG_PLACEHOLDER,
    "ast": 0,
    "blk": 0,
    "rbs": 0,
    "stl": 0,
    "fg_att": 0,
    "ft_att": 0,
    "fg_made": 0,
    "ft_made": 0,
    "three_pt_att": 0,
    "pts": 0,
    "fg_percentage": 0,
    "ft_percentage": 0,
    "three_pt": 0,
    "three_pt_percentage": 0,
    "homeaway": "away"
  }
  if(playerType === 'home'){
    return homePlayerZeroData;
  }
  if(playerType === 'away'){
    return awayPlayerZeroData;
  }
}
