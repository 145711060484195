import React from 'react';
import { Link, useParams } from 'react-router-dom';
import RequestData from '../logic/search_data/searchResult';
import PostPlayerGameData from '../logic/wp_spp/postPlayerGameData';
import HomeTeam from '../components/homeTeam';
import AwayTeam from '../components/awayTeam';
import GameInfo from '../components/gameInfo';
import GameScore from '../components/gameScore';
import GameClock from '../components/gameClock';
import CheckInternetConnection from '../utils/internetConnection';

const EventGame = () => {
  const { gameId } = useParams(); // Get games parameter
  const getGame = RequestData(process.env.REACT_APP_PAGE_TYPE_GAME, '_', gameId);
  const gameData = getGame.filter(games => games.id === gameId);
  const getRoster = RequestData(process.env.REACT_APP_PAGE_TYPE_ROSTER, gameId);

  return (
    <>
    <div className="flex flex-col items-center w-full bg-gray" id="statkeepHeader">
      <div className="flex justify-between items-center w-100">
        <div className="flex flex-row items-center">
          <Link to={`/events/${(gameData[0]) ? gameData[0].event_id : 'home'}`} className="max-h-16 font-urbanist text-black text-sm border-2 p-1 rounded-lg mx-3 h-50" >Back</Link>
          <div className="flex-flex-col">
            <CheckInternetConnection />
            <PostPlayerGameData gameData={gameData}/>
          </div>
        </div>
        {/* Display Internet Connection Status */}
        {/* Event Game Data */}
         { (getRoster) ? (<GameInfo gameData={gameData} />) : '' }
        {/* Game Clock */}
         { (getRoster) ? (<GameClock />) : '' }
      </div>
      {/* Game Score */}
      { (getRoster) ? (<GameScore gameData={gameData} />) : '' }
    </div>
      <div className="flex flex-row w-full">
        { (getRoster) ? (<HomeTeam rosterData={getRoster.home_players_data} jNumData={getRoster.home_players} eventId={getRoster.event_id} rosterType='home' />) : '' }
        { (getRoster) ? (<AwayTeam rosterData={getRoster.away_players_data} jNumData={getRoster.away_players} eventId={getRoster.event_id} rosterType='away' />) : '' }
      </div>
    </>
  );
};

export default EventGame