import React, { useEffect, useState } from 'react';
// import RequestData from '../logic/search_data/searchResult';
import SimpleTable from '../utils/table';
import { Link } from 'react-router-dom';
import CustomFormattedDate from '../utils/customFormattedDate';
import { realtimeDB } from '../firebaseConfig';
import { set, ref, child, getDatabase, get } from 'firebase/database';
import { getVar } from '../utils/customVariable';
import { getDivisions } from '../utils/divisionConverter';
import { siteUrl } from '../utils/siteUrl';

const GetGame = (eventData) => {
  const [requestData, setRequestData] = useState([{}]);
  const fullURL = window.location.href;
  const secretKeys = process.env.REACT_APP_REQUEST_SECRET_KEY;
  
  var authorizationHeader = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${secretKeys}`,
    },
  }
  const backendUrl = `${siteUrl(fullURL, 'expressJS')}/api/v1/${process.env.REACT_APP_PAGE_TYPE_GAME}/${eventData.eventId}/_`;

  useEffect(() => {
    const fetchData = () => {
      fetch(backendUrl, authorizationHeader)
      .then(response => response.json())
      .then(data => setRequestData(data))
      .catch(error => console.error('Error:', error));
    };
    fetchData();
  }, [eventData.eventId]);

  const filteredGames = requestData.message && (requestData.message).length > 0 ? (requestData.message).filter(games => games.event_id === eventData.eventId) : [];
  const gamePath = process.env.REACT_APP_PAGE_TYPE_GAME;
  const eventCourtColumns = [
    {
      Header: 'Court',
      accessor: 'court',
    },
    {
      Header: 'Time',
      accessor: 'time',
    },
    {
      Header: 'Division',
      accessor: 'division',
    },
    {
      Header: 'Home',
      accessor: 'home',
    },
    {
      Header: 'Away',
      accessor: 'away',
    },
    {
      Header: 'Score',
      accessor: 'game_score',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
  ];
  
  const clearSelections = () => {
    const allBtns = document.querySelectorAll('#courtSelector button');
    allBtns.forEach(btn => {
     btn.classList.remove('bg-white','text-black','p-1');
    })
  }
 
 const showAllCourts = () => {
    const allRows = document.querySelectorAll('.court_list tbody tr');
    allRows.forEach(row => {
      row.classList.remove('hidden');
    })
 } 
 
 const clearLocations = () => {
    const allBtns = document.querySelectorAll('#locationSelector button');
    allBtns.forEach(btn => {
     btn.classList.remove('bg-white','text-black');
    })
  }
 
 const showAllLocations = () => {
    const allRows = document.querySelectorAll('.court_list tbody tr');
    allRows.forEach(row => {
      row.classList.remove('hidden');
    })
 } 
 const handleAllCourtClick = (e) => {
   clearSelections();
   showAllCourts();
   e.currentTarget.classList.add('bg-white','text-black','p-1');
 } 
 const handleAllLocationClick = (e) => {
   clearLocations();
   showAllLocations();
   e.currentTarget.classList.remove('text-white');
   e.currentTarget.classList.add('bg-white','text-black','p-1');
 }
 const checkGameScore = (homeScore, awayScore) => {
   let gameScore = 'Not Available';
   if(homeScore !== null & awayScore !== null){ gameScore = homeScore + '-' + awayScore; }
   return gameScore;
 }

 const eventCourtData = filteredGames.map((game) => (
    {'location':game.location,'time': CustomFormattedDate(game.start_time), 'court': game.court, 'division': game.division, 'home':  getDivisions('girls', (game.home_team) ? game.home_team : ''), 'away': getDivisions('girls', (game.away_team) ? game.away_team : ''), 'game_score': checkGameScore(game.home_team_score, game.away_team_score), 'action': <Link to={{pathname: '/' + gamePath + '/' + game.id}} className="p-1 border-2 border-gray rounded-md">Record Stats</Link>}
  ));
  
  function isNumber(obj) { return !isNaN(obj); }
  
  const playerStatURL = getVar('fb-rt-stat', fullURL);
  const database = getDatabase();
  
  function saveGameFirst(event_id, gameId){
    const dbRef = ref(database);
    const path = `/playerStats/${playerStatURL}/${event_id}/${gameId}/`;
    get(child(dbRef, path)).then((snapshot) => {
      if (!snapshot.exists()){
        set(ref(realtimeDB, `/playerStats/${playerStatURL}/${event_id}/${gameId}`), '');
      }
    }).catch((error) => {
      console.error("Error reading Firebase Database", error);
    });
  }
  
  filteredGames.map((game) => {
    saveGameFirst(game.event_id, game.id);
  });
  
  return (
    <>
    <div className="w-full">
      <h3 className="mt-4 mb-2">{ (eventData.eventName) ? eventData.eventName : '' }</h3>
      <div className='mb-2' id="locationSelector">
          <button className="font-urbanist text-lg bg-white text-black border-2 rounded-lg py-1 px-2 hidden" id="allLocationsBtn" onClick={(e)=> handleAllLocationClick(e)}>All</button>
      </div>
      <div className="flex justify-center text-white font-urbanist bg-black shadow-md p-1 gap-2 overflow-auto hidden" id="courtSelector">
        <button className="font-urbanist text-lg bg-white text-black p-1" id="allCourtsBtn" onClick={(e)=> handleAllCourtClick(e)}>All</button>
      </div>
      <div className='court_list'>
        { isNumber(eventData.eventId) ? (
          <SimpleTable data={eventCourtData} columns={eventCourtColumns} />
        ) : (
          ''
        )}
      </div>
    </div>
    </>
  )
}

export default GetGame
