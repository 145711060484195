import React, { useEffect, useState } from 'react';

const GameClock = ({ initialMinutes, onExpire }) => {
  // Initialize the state with the total seconds
  const [checkSecond, setCheckSecond] = useState(initialMinutes * 60);
  const [isActive, setIsActive] = useState(true);
  const [isGameStarting, setIsGameStarting] = useState(false);
  const [gamePeriod, setGamePeriod] = useState();
  const [isHalfClockType, setIsHalfClockType] = useState(true);
  const selectorStyle = 'font-urbanist, text-black, text-base, border-2, p-1, rounded-lg';
  const selectedStyle = 'font-urbanist, text-white, bg-black, text-base, border-2, p-1, rounded-lg';

  useEffect(() => {
    // Exit early when we reach 0
    if (checkSecond === 0) {
      setIsActive(false);
      onExpire && onExpire();
      return;
    }

    // If timer is not active, do nothing
    if (!isActive) return;

    // Decrease seconds left every second if timer is active
    const intervalId = setInterval(() => {
      setCheckSecond((seconds) => seconds - 1);
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [isActive, checkSecond, onExpire]);

  // Pause the countdown
  const pauseTimer = () => {
    setIsActive(false);
  };

  // Resume the countdown
  const resumeTimer = () => {
    setIsActive(true);
  };

  // Format the seconds into mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    return `${minutes}:${secondsRemaining < 10 ? `0${secondsRemaining}` : secondsRemaining}`;
  };

  const setPeriod = (setMinutes) => { setCheckSecond(setMinutes*60); setIsGameStarting(true); setIsActive(true); }

  const setHalfTime = (setMinutes) => { setCheckSecond(setMinutes*60); setIsGameStarting(true); setIsActive(false); }
  const setOt = (setMinutes) => { setCheckSecond(setMinutes*60); setIsGameStarting(true); }
  const startClock = (setMinutes) => { setCheckSecond(setMinutes*60); setIsGameStarting(true); }
  
  const clickHandler = (e, bool) => {
    var btns = document.querySelectorAll('.selector_button');
    btns.forEach(btn => {
      btn.classList.remove('font-urbanist', 'text-white', 'bg-black', 'text-base', 'border-2', 'p-1', 'rounded-lg');
      btn.classList.add('font-urbanist', 'text-black', 'text-base', 'border-2', 'p-1', 'rounded-lg');
    })
    
    setIsHalfClockType(bool);
    
    e.currentTarget.classList.remove('font-urbanist', 'text-black', 'text-base', 'border-2', 'p-1', 'rounded-lg');
    e.currentTarget.classList.add('font-urbanist', 'text-white', 'bg-black', 'text-base', 'border-2', 'p-1', 'rounded-lg');
  }  
  
  const customTime = () => {
    let time = Number(window.prompt('Enter Time in Minutes'));
    console.log(typeof time);
    setIsActive(false);
    
    if (time != null) {
      startClock(time);
    } 
  }
  
  return (
    <>
    <div className="flex flex-row items-center">
      <div className="flex flex-col"> 
        <div className="block">
          <button className="selector_button mx-1 font-urbanist text-white bg-black text-base border-2 p-1 rounded-lg" onClick={(e) => customTime()}>Custom</button>
          <button className="selector_button mx-1 font-urbanist text-white bg-black text-base border-2 p-1 rounded-lg" onClick={(e) => clickHandler(e, true)}>Half/OT</button>
          <button className="selector_button mx-1 font-urbanist text-black text-base border-2 p-1 rounded-lg" onClick={(e) => clickHandler(e, false)}>Periods</button>
        </div>
        <div className={isHalfClockType ? 'hidden' : 'block'}> 
          <button className="period_selector text-black text-lg mx-1" onClick={() => setPeriod(10)}>P1</button>
          <button className="period_selector text-black text-lg mx-1" onClick={() => setPeriod(10)}>P2</button>
          <button className="period_selector text-black text-lg mx-1" onClick={() => setPeriod(10)}>P3</button>
          <button className="period_selector text-black text-lg mx-1" onClick={() => setPeriod(10)}>P4</button>
        </div>
        {/* <button onClick={() => setHalf(20)}>Half</button> */}
        <div className={isHalfClockType ? 'block' : 'hidden'}> 
          <button className='half_selector text-black text-lg mx-1' onClick={() => setHalfTime(20)}>Half</button>
          <button className='half_selector text-black text-lg mx-1' onClick={() => setOt(10)}>OT</button>
        </div> 
      </div>
      <div className="flex flex-col m-1 "> 
        <div className="text-center">
          { checkSecond > 0 ? <button className="text-black font-bold text-lg text-center">{formatTime(checkSecond)}</button> : '' }
        </div>
        <button className="font-urbanist text-white bg-black text-base border-2 p-1 rounded-lg" onClick={resumeTimer}>Start Clock</button>
        { isGameStarting ? isActive ? (
          <button className="font-urbanist text-white bg-black text-base border-2 p-1 rounded-lg" onClick={pauseTimer}>Pause</button>
        ) : (
//           <button className="text-black text-lg text-center" onClick={resumeTimer}>Resume</button>
          ''
        ) : '' }
      </div>
    </div>
    </>
  );
};

export default GameClock