import React, { useState, useEffect, useRef } from 'react';
import BenchRoster from './benchRoster';
import RosterDNP from './rosterDNP';
import ActiveRoster from './activeRoster';
import StatKeep from '../utils/statKeep';
// import { playerZero } from '../utils/playerZero';
// import { countActivePlayers } from '../utils/counting';
import { useParams } from 'react-router-dom';
import { realtimeDB } from '../firebaseConfig';
import { get, child, ref, set, getDatabase, onValue, update } from 'firebase/database';
import { getVar } from '../utils/customVariable';
import { countActivePlayers } from '../utils/counting';

const RosterData = (props) => {  
  const rosterData = props.rosterData;
  const playerJnum = (props.jNumData) ? JSON.parse(props.jNumData) : '';
  const eventId = props.eventId;
  const rosterType = props.rosterType;
  const [newRosterData, setNewRosterData] = useState([]);
  const [activePlayerList, setActivePlayerList] = useState([]);
  const [dnpPlayerList, setDnpPlayerList] = useState([]);
  const [playerState, setPlayerState] = useState([]);
  const [isPlayerSelect, setIsPlayerSelect] = useState(false);
  const [isStatKeep, setIsStatKeep] = useState(false);
  const [isFirstStart, setIsFirstStart] = useState(true);
  const [hasSavedData, setHasSavedData] = useState(false);
  const teamType = props.teamType;
  const { gameId } = useParams(); // Get events parameter
  const fullURL = window.location.href;
  const playerStatURL = getVar('fb-rt-stat', fullURL);
  const database = getDatabase();
  
  const newPlayerList = (playerData, playerJNum) => ({
    id: playerData.id,
    name: playerData.name,
    j_num: playerJNum,
    profile_img: playerData.profile_img,
    homeaway: playerData.homeaway
  });
  const savedActivePlayerList = (activePlayerIds, playerJnum) => {
    if(activePlayerIds !== undefined){
      const savedPlayers = Object.values(rosterData).filter(playerRoster => activePlayerIds.includes(playerRoster.id));
      const newSavedPlayerData = [];
      Object.keys(savedPlayers).forEach(key => newSavedPlayerData.push( { ...savedPlayers[key] , "j_num": playerJnum[savedPlayers[key].id]['j_num'] } ));
      return newSavedPlayerData;
    }else{
      return '';
    }
  };
  
//   useEffect(() => {
//     let isMounted = true;

//     const fetchData = () => {
//       const unsubscribe = onValue(ref(realtimeDB), (snapshot) => {
//         if (!isMounted) return; // Prevent state updates if component is unmounted

//         const userData = snapshot.val();
//         const playerStatsData = userData?.playerStats?.[playerStatURL]?.[eventId]?.[gameId] || {};

//         const getRosterPlayers = Object.values(playerStatsData).filter(players => players.player_status === 'bench');
//         const rosterPlayerIds = getRosterPlayers.map(roster_player => roster_player.player_id);

//         const getActivePlayers = Object.values(playerStatsData).filter(players => players.player_status === 'active');
//         const activePlayerIds = getActivePlayers.map(active_player => active_player.player_id);

//         const getDnpPlayers = Object.values(playerStatsData).filter(players => players.player_status === 'dnp');
//         const dnpPlayerIds = getDnpPlayers.map(dnp_player => dnp_player.player_id);

//         setNewRosterData(savedActivePlayerList(rosterPlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));
//         setDnpPlayerList(savedActivePlayerList(dnpPlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));
//         setActivePlayerList(savedActivePlayerList(activePlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));

//         const getEveryId = Object.values(rosterData).map(player => player.id);
//         const getSavedIds = Object.values(playerStatsData);
//         const savedIds = getSavedIds.map(obj => obj.player_id);

//         const filteredIds = getEveryId.filter(id => !savedIds.includes(id));
//         filteredIds.forEach(id => {
//             saveActivePlayer(eventId, gameId, id, 'bench');
//         });
//       });
//       return () => { unsubscribe(); };
//     };
//     // Execute the effect to fetch data
//     fetchData();
//     // Clean up on unmount
//     return () => { isMounted = false; };
// }, []);
  
 const isMounted = useRef(true);
 useEffect(() => {
    const fetchData = () => {
      const unsubscribe = onValue(ref(realtimeDB), (snapshot) => {
        if (!isMounted.current) return; // Prevent state updates if component is unmounted

        const userData = snapshot.val();
        const playerStatsData = userData?.playerStats?.[playerStatURL]?.[eventId]?.[gameId] || {};

        const getRosterPlayers = Object.values(playerStatsData).filter(players => players.player_status === 'bench');
        const rosterPlayerIds = getRosterPlayers.map(roster_player => roster_player.player_id);

        const getActivePlayers = Object.values(playerStatsData).filter(players => players.player_status === 'active');
        const activePlayerIds = getActivePlayers.map(active_player => active_player.player_id);

        const getDnpPlayers = Object.values(playerStatsData).filter(players => players.player_status === 'dnp');
        const dnpPlayerIds = getDnpPlayers.map(dnp_player => dnp_player.player_id);

        setNewRosterData(savedActivePlayerList(rosterPlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));
        setDnpPlayerList(savedActivePlayerList(dnpPlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));
        setActivePlayerList(savedActivePlayerList(activePlayerIds, playerJnum).sort((i, j) => i.j_num - j.j_num));

        const getEveryId = Object.values(rosterData).map(player => player.id);
        const getSavedIds = Object.values(playerStatsData);
        const savedIds = getSavedIds.map(obj => obj.player_id);

        const filteredIds = getEveryId.filter(id => !savedIds.includes(id));
        filteredIds.forEach(id => {
          saveActivePlayer(eventId, gameId, id, 'bench');
        });
      });

      return unsubscribe;
    };

    const unsubscribe = fetchData();

    // Clean up on unmount
    return () => {
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [playerStatURL, eventId, gameId, realtimeDB, savedActivePlayerList, saveActivePlayer, playerJnum, rosterData]);

  function saveActivePlayer(eventId, gameId, playerId, playerStatus){
    const dbRef = ref(database);
    const path = `/playerStats/${playerStatURL}/${eventId}/${gameId}/${playerId}`;
    get(child(dbRef, path)).then((snapshot) => {
      if (!snapshot.exists()){
        set(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${gameId}/${playerId}`), {
          player_status: playerStatus,
          player_id: playerId,
        });
      }else{
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${gameId}/${playerId}`), {
          player_status: playerStatus,
        });
      }
    }).catch((error) => {
      console.error("Error reading Firebase Database", error);
    });
    
  }
//   function checkSavedGame(eventId, gameId) {
//     const dbRef = ref(realtimeDB);
//     const path = `/playerStats/${playerStatURL}/${eventId}/${gameId}`;
//     get(child(dbRef, path)).then((snapshot) => {
//       if (!snapshot.exists()){
//         console.log('dne', snapshot.val());
// //         console.table(rosterData);
        
// //         rosterData.forEach(player => addToBench(player));
//       }else{
//         const userData = snapshot.val();
//         console.log('exists', Object.values(userData['playerStats'][playerStatURL][eventId][gameId]));
        
//       }
//     }).catch((error) => {
//       console.error("Error reading Firebase Database", error);
//     });
//   }
  
//   checkSavedGame(eventId, gameId);
  
  const addToActive = (playerData, playerJNum) => {    
    setActivePlayerList(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerJNum) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...newRosterData ];
    const filteredMainRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setNewRosterData(filteredMainRoster);
    saveActivePlayer(eventId, gameId, playerData.id, 'active');
  };
  const addToDNP = (playerData, playerJNum) => {
    setDnpPlayerList(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerJNum) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...newRosterData ];
    const filteredMainRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setNewRosterData(filteredMainRoster);
    saveActivePlayer(eventId, gameId, playerData.id, 'dnp');
  };
  const addToBench = (playerData) => {
    setNewRosterData(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerData.j_num) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...activePlayerList ];
    const filteredActiveRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setActivePlayerList(filteredActiveRoster);
    saveActivePlayer(eventId, gameId, playerData.id, 'bench');
  };
  const dnpToBench = (playerData) => {
    setNewRosterData(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerData.j_num) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...dnpPlayerList ];
    const filteredDnpRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setDnpPlayerList(filteredDnpRoster);
    saveActivePlayer(eventId, gameId, playerData.id, 'bench');
  }
  const dnpToActive = (playerData) => {
    setActivePlayerList(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerData.j_num) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...dnpPlayerList ];
    const filteredDnpRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setDnpPlayerList(filteredDnpRoster);
    saveActivePlayer(eventId, gameId, playerData.id, 'active');
  }
  const activeToDNP = (playerData) => {
    setDnpPlayerList(prevPlayerList => [ ...prevPlayerList, newPlayerList(playerData, playerData.j_num) ].sort((i, j) => i.j_num - j.j_num));
    // Remove player from default roster and update state
    const newData = [ ...activePlayerList ];
    const getNewBenchData = [ ...newRosterData ];
    const filteredActiveRoster = newData.filter(playerRoster => playerRoster.id !== playerData.id);
    setActivePlayerList(filteredActiveRoster);
//     saveActivePlayer(eventId, gameId, playerData.id, 'dnp');
  }
    
//   const subToActiveHandler = (e, playerData, getPlayerJnum) => {
//     let playerElement = e.currentTarget.closest('.bench-player');
//     let teamLoc = e.currentTarget.closest('.roster-container').getAttribute('data-teamtype');
    
// //     if active is < 5 - just add to Active section
//    if(countActivePlayers(teamLoc) < 5) {
//      addToActive(playerData, getPlayerJnum);
//    } else { //begin logic for subbing tag in
//      let selectedJnum = getPlayerJnum;
//      let isUnique = true;
//      let playerToRemove;
//      let filteredQueue;
     
// //      console.log('og active',  activeQueue);
     
// //   check to see if its already in the array - if so cancel the selection
//      activeQueue.forEach(player => {
//        if(selectedJnum == player.getPlayerJnum) {
//          playerElement.classList.remove('border-2');
//          playerToRemove = player;
//          isUnique = false;
//        }
//      });
     
// //      if its not in the active queue array, add it
//      if(isUnique === true) {
//        playerElement.classList.add('border-2');
//        if(activeQueue.length == 0 ) {
//          setActiveQueue([{playerData,getPlayerJnum}]);
//        } else {
//         setActiveQueue([ ...activeQueue, {playerData,getPlayerJnum} ])
//        }
//      } else { //for cancelling selection
// //     //      if the number is matching inside of current active queue
//          setActiveQueue(activeQueue.filter((player) => {
//              if(player != playerToRemove) {
//                return player;
//              }
//            })
//          );
//       } //end else
     
//    } //end logic for subbing tag in
//   } //end function

//   const benchHandler =  (selectedPlayer) => {
// //     if no one in queue - straight to bench
//     if(activeQueue.length == 0) {
//       addToBench(selectedPlayer);
//     } else if(activeQueue.length >= 2){ //when array is 2 or more slice activeQueue array to remove 1st item
//         let firstInLine = activeQueue[0];
//   //     Add in the first player and then remove it from the array
//         addToActive(firstInLine.playerData, firstInLine.getPlayerJnum);
// //         let slicedArr = activeQueue.slice(1);
// //       console.log(slicedArr);
//         setActiveQueue(activeQueue.slice(1));
      
// //   //       Then, add the active player to bench
//         addToBench(selectedPlayer);
//     } else { //clear activeQueue array completely on last person in line
//       console.log('last one' , activeQueue[0]);
    
//         addToActive(activeQueue[0].playerData, activeQueue[0].getPlayerJnum);
//         addToBench(selectedPlayer);
//        setActiveQueue([]);
//       console.log('after', activeQueue);
//     }
//   }
  
//   check if theres save info for game already, if not initially load everyone into bench
  
  const clearActiveSelection = () => {
    
    document.querySelectorAll('#activePlayers .active-player').forEach(player => {
      player.classList.remove('p-1', 'rounded-md');
      player.style.background = 'none';
    })
  }
  
  const selectPlayer = (e, playerData) => {
    if(isStatKeep === true) {
      if(e.target.nodeName === 'BUTTON') {
        return;
      } else {
        let selectedActive = e.currentTarget;

        clearActiveSelection();

        selectedActive.style.background = 'linear-gradient(to bottom, #283048, #859398)';
    //     selectedActive.classList.add('bg-gray-dark');
        selectedActive.classList.add('rounded-md');
        setPlayerState([playerData, eventId, rosterType]);
        
//         trigger for stat keep ui
        setIsPlayerSelect(true);
      }
    } else return;
  }
  
  
  
  const formatActiveRoster = (teamLocation) => {
      setIsStatKeep(true);
    
      const statWrapper = document.querySelector('.stat-wrapper');
      const teamSection = document.querySelector('#' + teamLocation + 'Roster');
      const startRosterBtn = document.querySelector('#' + teamLocation + 'Roster #'+teamLocation+'StartRoster');
      const benchSection = document.querySelector('#' + teamLocation + 'Roster #benchSection');
      const activeSection = document.querySelector('#' + teamLocation + 'Roster #activeSection');
      const dnpSection = document.querySelector('#' + teamLocation + 'Roster #dnpSection');
      const benchRoster = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster > div');
      const benchStartBtn = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster #benchStart');
      const benchDNPbtn = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster #benchDNP');
      const activePlayerRow = document.querySelector('#' + teamLocation + 'Roster .home_active_roster'); 
      const statKeepContainer = document.querySelector('#' + teamLocation + 'Roster #statKeepContainer');
      
      
          teamSection.classList.add('roster-active');
          startRosterBtn.classList.add('hidden');
          dnpSection.classList.add('hidden');

          //    player zero hide
             benchSection.querySelector('input').classList.add('hidden');

          //     add min height to bench section
              benchSection.classList.add('w-1/5','h-full', 'bg-black', 'overflow-y-scroll', 'no-scrollbar','no-scrollbar','z-10','border-r-2');
              benchSection.classList.remove('col-4', 'col-md-4');

              benchSection.querySelectorAll('h1, h2, p').forEach(heading => {
                heading.classList.remove('text-black');
                heading.classList.add('text-white');
              })

              benchStartBtn.forEach(button => {
                button.classList.remove('text-black');
                button.classList.add('text-white', 'border-white');
              })

              benchDNPbtn.forEach(button => {
                button.classList.add('hidden');
              })

              activeSection.classList.remove('col-4', 'col-md-4');
              activeSection.classList.add('w-4/5', 'absolute','bottom-0');


              activeSection.classList.add('bg-black');

              activePlayerRow.classList.add('flex','flex-row',  'flex-wrap');

              if(teamLocation === 'home') {
              activeSection.classList.add('right-0');
                statKeepContainer.classList.add('absolute', 'top-0','right-0','h-3/4'); 
                activePlayerRow.classList.add('justify-end');
                benchSection.classList.add('border-r-2');
              } else if(teamLocation === 'away'){
              activeSection.classList.add('left-0');
                statKeepContainer.classList.add('absolute', 'top-0','left-0','h-3/4'); 
                benchSection.classList.add('relative', 'left-full');
                benchSection.style.transform = 'translateX(-100%)';
                activePlayerRow.classList.add('justify-start');
                benchSection.classList.add('border-l-2');
              }
  }
  const startRoster = (teamType) => { 
     let teamLocation;
     let homeScore = parseInt(document.querySelector('.home_score span').innerText);
     let awayScore = parseInt(document.querySelector('.away_score span').innerText);
     let skipStart = false;
    
      switch(teamType) {
        case 'home': teamLocation = 'home';
          break;
        case 'away': teamLocation = 'away';
          break;
      }
//     check home / away score - if > 0 than means game has started, start bypass for moving all players to active requirement.
    if(homeScore > 0 || awayScore > 0) {
          skipStart = true;
    }
    
      
//  Condition to start roster: bench at 0 players only on first time
    if(isFirstStart) {
        if(skipStart == true) {
          formatActiveRoster(teamLocation);
          setIsFirstStart(false);
        } else {
          if(document.querySelectorAll('#' + teamType + 'Roster .bench_roster .bench-player').length === 0) {
  //           move all active players to bench on first roster start
            activePlayerList.forEach(player => {
              addToBench(player);
            })
            setActivePlayerList([]);
            setIsFirstStart(false);
            formatActiveRoster(teamLocation);

          } else {
            window.alert('Must move all players to either Inactive or Active columns before starting roster.')
          }
        }
      } else {
  //       allow players to be on roster for edit btn into re-start
        formatActiveRoster(teamLocation);
      }
   
    
  }
  
//     if(timeoutStarted ==false){ 
//        const scoreCheck = setTimeout(function() {
//        let checkHomeScore = parseInt(document.querySelector('.home_score span').innerText);
//        let checkAwayScore = parseInt(document.querySelector('.away_score span').innerText);
//         if(checkHomeScore > 0 || checkAwayScore > 0) {
//               formatActiveRoster('home');
//               formatActiveRoster('away');
//         }
//         setTimeoutStarted(true);
//       },5000)
//     }
  
  const editRoster = (team) => {
//     setTimeoutStarted(true);
    
    //close active stat keep section
       setPlayerState([]);
    clearActiveSelection();
    
    
    
      let teamLocation;

      switch(teamType) {
        case 'home': teamLocation = 'home';
          break;
        case 'away': teamLocation = 'away';
          break;
      }
      const statWrapper = document.querySelector('.stat-wrapper');
      const teamSection = document.querySelector('#' + teamLocation + 'Roster');
      const startRosterBtn = document.querySelector('#' + teamLocation + 'Roster #'+teamLocation+'StartRoster');
      const benchSection = document.querySelector('#' + teamLocation + 'Roster #benchSection');
      const activeSection = document.querySelector('#' + teamLocation + 'Roster #activeSection');
      const dnpSection = document.querySelector('#' + teamLocation + 'Roster #dnpSection');
      const benchRoster = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster > div');
      const benchStartBtn = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster #benchStart');
      const benchDNPbtn = document.querySelectorAll('#' + teamLocation + 'Roster #benchRoster #benchDNP');
      const activePlayerRow = document.querySelector('#' + teamLocation + 'Roster .home_active_roster'); 
      const statKeepContainer = document.querySelector('#' + teamLocation + 'Roster #statKeepContainer');
    
    
    //     switch order of bench and dnpPlayer
      benchSection.classList.remove('order-1');
      benchSection.classList.add('order-2')
    
      dnpSection.classList.remove('order-2');
      dnpSection.classList.add('order-1')
    
      
//       Condition to change section: bench at 0 players
          setIsStatKeep(false);
      
          teamSection.classList.remove('roster-active');
          startRosterBtn.classList.remove('hidden');
          dnpSection.classList.remove('hidden');

          //    player zero hide
             benchSection.querySelector('input').classList.remove('hidden');

          //     remove min height to bench section
              benchSection.classList.remove('w-1/5','h-full', 'bg-black', 'overflow-y-scroll','no-scrollbar','z-10','border-r-2');
              benchSection.classList.add('col-4', 'col-md-4');


              benchDNPbtn.forEach(button => {
                button.classList.remove('hidden');
              })

              activeSection.classList.add('col-4', 'col-md-4');
              activeSection.classList.remove('w-4/5', 'absolute','bottom-0');


              activeSection.classList.remove('bg-black');

              activePlayerRow.classList.remove('flex','flex-row',  'flex-wrap');

              if(teamLocation === 'home') {
              activeSection.classList.remove('right-0');
                statKeepContainer.classList.remove('absolute', 'top-0','right-0','h-3/4'); 
                statKeepContainer.classList.remove('justify-end'); 
                benchSection.classList.remove('border-r-2');
              } else if(teamLocation === 'away'){
              activeSection.classList.remove('left-0');
                statKeepContainer.classList.remove('absolute', 'top-0','left-0','h-3/4'); 
                benchSection.classList.remove('relative', 'left-full');
                benchSection.style.transform = 'translateX(0)';
                statKeepContainer.classList.remove('justify-start'); 
                benchSection.classList.remove('border-l-2');
              }
    
  }
  
  
  

  return (
    <>
    <div className="roster-container w-6/12 max-h-80vh min-h-80vh container relative bg-gray-dark rounded-md m-1 overflow-auto no-scrollbar" id={teamType + 'Roster'} data-teamtype={teamType}>
       <button className="bg-white text-black text-lg font-bold p-1 my-1 rounded-lg" onClick={() => {startRoster(teamType)}} id={teamType + "StartRoster"}>Start Roster</button>  
      <div className="row h-full relative">
        <div className="col-4 col-md-4 order-1" id="benchSection">
          <div className="bench_roster" id="benchRoster">
            {isStatKeep ? <button className="mx-1 font-urbanist text-white text-base border-white border-1 p-1 rounded-lg" onClick={() => editRoster(teamType)}>Edit</button> : ''}
            <h1 className={isStatKeep ? 'hidden' : 'text-white text-lg'}>{isFirstStart ? 'Roster' : 'Bench'}</h1>
            { (newRosterData) ?  
              (<BenchRoster newBenchData={newRosterData} playerJnum={playerJnum} addToActive={addToActive} addToDNP={addToDNP} isStatKeep={isStatKeep} />)
            : '' }
          </div>
        </div>
        <div className="col-4 col-md-4 order-2" id="dnpSection">
          <h1 className="text-white text-lg">Inactive</h1>
          { (dnpPlayerList) ?  
            (<RosterDNP dnpPlayerList={dnpPlayerList} dnpToActive={dnpToActive} dnpToBench={dnpToBench} isStatKeep={isStatKeep}/>)
          : '' }
        </div>
        <div className="col-4 col-md-4 order-3"  id="activeSection">
          <div className="row">
            <div className="col-12 col-md-12" id="activePlayers">
              <h1 className={isStatKeep ? 'hidden' :'text-white text-lg'}>Active</h1>
              { (activePlayerList) ?  
                (<ActiveRoster activePlayerList={activePlayerList} addToBench={addToBench} activeToDNP={activeToDNP} selectPlayer={selectPlayer} isStatKeep={isStatKeep} eventId={eventId} gameId={gameId}/>)
              : '' }
            </div>
          </div>
        </div>
        <div className=" w-9/12 order-4" id="statKeepContainer">
          {/* Active player stat platform */}
          { (isPlayerSelect) ?  
            (<StatKeep activePlayerList={playerState}/>)
          : '' }
        </div>
      </div>
    </div>
    </>
  );
}

export default RosterData