import { useState, useEffect } from 'react';
import { siteUrl } from '../../utils/siteUrl';

const RequestData = (requestDataType, requestId, gameEventList) => {
  const [requestData, setRequestData] = useState([{}]);
  const [newRequestData, setNewRequestData] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const fullURL = window.location.href;
  if( requestId === undefined ){ requestId = '_'; }
  if( gameEventList === undefined ){ gameEventList = '_'; }
  const backendUrl = `${siteUrl(fullURL, 'expressJS')}/api/v1/${requestDataType}/${requestId}/${gameEventList}`;
  const secretKeys = process.env.REACT_APP_REQUEST_SECRET_KEY;
  
  var authorizationHeader = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${secretKeys}`,
    },
  }
  useEffect(() => {
    const fetchData = () => {
      fetch(backendUrl, authorizationHeader)
      .then(response => response.json())
      .then(data => setRequestData(data))
      .catch(error => console.error('Error:', error));
    };
    fetchData();
  }, []);

  switch (requestDataType) {
    case process.env.REACT_APP_PAGE_TYPE_EVENT:
      if(!isRendered){
        if(typeof requestData.message !== 'undefined'){
          // Hardcode date to exclude any events before this date for the new stat platform
          const targetDate = "2020-02-28 00:00:00";
          const filteredEventDate = (requestData.message).filter(obj => obj.eventtime >= targetDate);
          const getData = filteredEventDate.map((result, i) => 
            ({'event_id': result.id, 'event_name': result.name, 'event_nickname': result.nickname})
          );
          setNewRequestData(getData);
        }
        if((newRequestData).length > 0){
          setIsRendered(true);
        }
      }
      return newRequestData;
      break;
    case process.env.REACT_APP_PAGE_TYPE_GAME:
      if(requestData.message !== undefined){
        if(!isRendered){
          const getData = requestData.message.map((result, i) => ({
            'id': result.id,
            'event_id': result.event_id, 
            'event_name': result.event_name, 
            'court': result.court, 
            'division': result.division,
            'start_time': result.start_time,
            'location': result.location,
            'home_team': result.home_team,
            'home_logo': result.home_logo,
            'home_team_score': result.home_team_score,
            'away_team': result.away_team,
            'away_logo': result.away_logo,
            'away_team_score': result.away_team_score,
            'exposure_game_id': result.exposure_game_id,
            'updatetime': result.updatetime,
            'bracket_name': result.bracket_name
          }));
          setNewRequestData(getData);
          setIsRendered(true);
        }
      }
      // if(newRequestData.length > 0){ return newRequestData; }
      return newRequestData;
      break;
    case process.env.REACT_APP_PAGE_TYPE_ROSTER:
      // Return all roster fields data
      if(requestData.id !== undefined){ return requestData; }
      break;
    default:
      console.log(`Can not pull data type: ${requestDataType} with param ${requestId}.`);
  }
};

export default RequestData;