export const getDivisions = (type, args) => {
  switch(type){
    case 'girls':
      if(args.includes('47U')){
        return args.replace('47U', 'Varsity Girls');
      }
      else if(args.includes('46U')){
        return args.replace('46U', 'JV Girls');
      }
      else if(args.includes('45U')){
        return args.replace('45U', 'Frosh/Soph Girls');
      }
      else if(args.includes('44U')){
        return args.replace('44U', 'Girls 8th Grade');
      }
      else if(args.includes('43U')){
        return args.replace('43U', 'Girls 7th Grade');
      }
      else if(args.includes('42U')){
        return args.replace('42U', 'Girls 6th Grade');
      }
      else if(args.includes('41U')){
        return args.replace('41U', 'Girls 5th Grade');
      }
      else if(args.includes('40U')){
        return args.replace('40U', 'Girls 4th Grade');
      }
      else if(args.includes('39U')){
        return args.replace('39U', 'Girls 3rd Grade');
      }
      else{
        return args;
      }
      break;
  }
}