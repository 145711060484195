import './styles/App.css';
import './logic/sentry/instrument';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EventCourt from './pages/eventCourt';
import EventGame from './pages/eventGame';
import LoginPage from './pages/loginPage';
import LogoutPage from './pages/logoutPage';
import ProtectedRoute from './logic/authVerification';
// import FirebaseRealtime from './components/firebaseRealtimeCRUD';
// import TestingCRUD from './components/testingFirebaseConnection';
// import { auth } from './firebaseConfig';

function App(){
  const eventPath = process.env.REACT_APP_PAGE_TYPE_EVENT;
  const gamePath = process.env.REACT_APP_PAGE_TYPE_GAME;
  return (
    <div className="App">
      <div className="headerNav">
        <>
        <LogoutPage />
        </>
      </div>
      <header className="App-header">
        <Router>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path={ eventPath + "/:requestId"} element={<EventCourt />} />
              <Route path={ gamePath + "/:gameId"} element={<EventGame />} />
            </Route>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
