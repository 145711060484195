export const getVar = (type, args) => {
  switch(type){
    case 'fb-rt-stat':
      let fullUrl = args;
      if( fullUrl.includes('dev.') ){
        return 'Dev';
      }else{
        return 'Live';
      }
      break
  }
}