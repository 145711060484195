import React, { useState, useEffect } from 'react';
import { realtimeDB } from '../firebaseConfig';
import { set, ref, onValue, update, child, getDatabase, get } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { getVar } from './customVariable';

const StatKeep = ({activePlayerList}) => {
  const [userList, setUserList] = useState([]);
  const [fgPercentage, setFgPercentage] = useState(0);
  const [ftPercentage, setFtPercentage] = useState(0);
  const [totalGameScore, setTotalGameScore] = useState({});
  const { gameId } = useParams(); // Get events parameter
  const [isSavedGame, setIsSavedGame] = useState(false);

  // Get players from FB DB
  useEffect(() => {
    onValue(ref(realtimeDB), (snapshot) => {
      setUserList([]);
      const userData = snapshot.val();
        if(userData !== null){
          Object.values(userData).map((user) => {
            setUserList(oldArray => [ ...oldArray, user ]);
          });
        }
    });
  }, []);
  const fullURL = window.location.href;
  const playerStatURL = getVar('fb-rt-stat', fullURL);
  
  // Check record. If it does not exist, create it
  const database = getDatabase();
  function checkRecordExists(event_id){
    const dbRef = ref(database);
    const path = `/playerStats/${playerStatURL}/${event_id}/${gameId}/${activePlayerList[0].id}/event_id`;
//     const path = `/playerStats/${event_id}/${gameId}/${activePlayerList[0].id}`;
    get(child(dbRef, path)).then((snapshot) => {
      if (!snapshot.exists()){
        // Create player and initialize stats
        const playerId = activePlayerList[0].id;
        const statPoint = 0;
        set(ref(realtimeDB, `/playerStats/${playerStatURL}/${activePlayerList[1]}/${gameId}/${playerId}`), {
          event_id: activePlayerList[1],
          player_id: playerId,
          player_status: 'active',
          game_id: gameId,
          ft_made: 0,
          ft_att: 0,
          fg_made: 0,
          fg_att: 0,
          total_fg_made: 0,
          total_fg_att: 0,
          three_pt: 0,
          three_pt_att: 0,
          pts: 0,
          fg_percentage: 0,
          ft_percentage: 0,
          three_pt_percentage: 0,
          rbs: 0,
          ast: 0,
          stl: 0,
          blk: 0,
          start_time: 0,
          stop_time: 0,
          time_pl: 0,
          roster_status: null,
          roster_type: activePlayerList[2],
        });
      }
    }).catch((error) => {
      console.error("Error reading Firebase Database", error);
    });
  }
  // Check record every time new player is selected
  if(typeof(activePlayerList[0]) !== 'undefined'){ checkRecordExists(activePlayerList[1]); }
  
  const handleFtMade = (playerData, eventId) => {
    const getNewFtMade = Number(playerData.ft_made) + 1;
    const getNewFtAtt = Number(playerData.ft_att) + 1;
    const getNewFtPercentage = Math.ceil(( Number(getNewFtMade) / Number(getNewFtAtt)) * 100);
    const totalPoints = getNewFtMade + (playerData.fg_made * 2) + (playerData.three_pt * 3);
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      pts: totalPoints,
      ft_percentage: getNewFtPercentage,
      ft_made: getNewFtMade,
      ft_att: getNewFtAtt,
    });
  };
  const handleFtAtt = (playerData, eventId) => {
    const getNewFtAtt = Number(playerData.ft_att) + 1;
    const getNewFtPercentage = Math.ceil(( Number(playerData.ft_made) / Number(getNewFtAtt)) * 100);
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      ft_percentage: getNewFtPercentage,
      ft_att: getNewFtAtt,
    });
  };
  const handleTwoPtMade = (playerData, eventId) => {
    const getNewTwoPtMade = Number(playerData.fg_made) + 1;
    const getNewTwoPtAtt = Number(playerData.fg_att) + 1;
    const getNewFgPercentage = Math.ceil((( Number(getNewTwoPtMade) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
    const totalPoints = (playerData.ft_made) + (getNewTwoPtMade * 2) + (playerData.three_pt * 3);
    const totalFgMade =  getNewTwoPtMade + playerData.three_pt;
    const totalFgAtt =  getNewTwoPtAtt + playerData.three_pt_att;
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      pts: totalPoints,
      fg_percentage: getNewFgPercentage,
      fg_made: getNewTwoPtMade,
      fg_att: getNewTwoPtAtt,
      total_fg_made: totalFgMade,
      total_fg_att: totalFgAtt,
      roster_type: activePlayerList[2],
    });
  };
  const handleTwoPtAtt = (playerData, eventId) => {
    const getNewTwoPtAtt = Number(playerData.fg_att) + 1;
    const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
    const totalFgAtt =  getNewTwoPtAtt + playerData.three_pt_att;
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${playerData.event_id}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      fg_percentage: getNewFgPercentage,
      fg_att: getNewTwoPtAtt,
      total_fg_att: totalFgAtt,
    });
  };
  const handleThreePtMade = (playerData, eventId) => {
    const getNewThreePtMade = Number(playerData.three_pt) + 1;
    const getNewThreePtAtt = Number(playerData.three_pt_att) + 1;
    const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(getNewThreePtMade) ) / ( Number(playerData.fg_att) + Number(getNewThreePtAtt) )) * 100);
    const getNewThreePtPercentage = Math.ceil( ((Number(getNewThreePtMade)) / (Number(getNewThreePtAtt))) * 100);
    const totalPoints = (playerData.ft_made) + (playerData.fg_made * 2) + (getNewThreePtMade * 3);
    const totalFgMade =  getNewThreePtMade + playerData.fg_made;
    const totalFgAtt =  getNewThreePtAtt + playerData.fg_att;
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      pts: totalPoints,
      fg_percentage: getNewFgPercentage,
      three_pt: getNewThreePtMade,
      three_pt_att: getNewThreePtAtt,
      three_pt_percentage: getNewThreePtPercentage,
      total_fg_made: totalFgMade,
      total_fg_att: totalFgAtt,
    });
  };
  const handleThreePtAtt = (playerData, eventId) => {
    const getNewThreePtAtt = Number(playerData.three_pt_att) + 1;
    const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(playerData.fg_att) + Number(getNewThreePtAtt) )) * 100);
    const getNewThreePtPercentage = Math.ceil( ((Number(playerData.three_pt)) / (Number(getNewThreePtAtt))) * 100);
    const totalFgAtt =  getNewThreePtAtt + playerData.fg_att;
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      fg_percentage: getNewFgPercentage,
      three_pt_att: getNewThreePtAtt,
      three_pt_percentage: getNewThreePtPercentage,
      total_fg_att: totalFgAtt,
    });
  };
  const handleAst = (playerData, eventId, operationType) => {
    let getNewAst = Number(playerData.ast) + 1;
    if(operationType === 'minus'){
      getNewAst = Number(playerData.ast) - 1;
      if(getNewAst < 0){ getNewAst = 0; }
    }
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      ast: getNewAst,
    });
  };
  const handleReb = (playerData, eventId, operationType) => {
    let getNewReb = Number(playerData.rbs) + 1;
    if(operationType === 'minus'){
      getNewReb = Number(playerData.rbs) - 1;
      if(getNewReb < 0){ getNewReb = 0; }
    }
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      rbs: getNewReb,
    });
  };
  const handleStl = (playerData, eventId, operationType) => {
    let getNewStl = Number(playerData.stl) + 1;
    if(operationType === 'minus'){
      getNewStl = Number(playerData.stl) - 1;
      if(getNewStl < 0){ getNewStl = 0; }
    }
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      stl: getNewStl,
    });
  };
  const handleBlk = (playerData, eventId, operationType) => {
    let getNewBlk = Number(playerData.blk) + 1;
    if(operationType === 'minus'){
      getNewBlk = Number(playerData.blk) - 1;
      if(getNewBlk < 0){ getNewBlk = 0; }
    }
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      event_id: eventId,
      player_id: playerData.player_id,
      blk: getNewBlk,
    });
  };
  const handlePersonalFoul = (playerData, eventId, operationTyp) => {
    let getDefaultPF = 0;
    if(playerData.pfs === undefined){ getDefaultPF = 0; }else{ getDefaultPF = playerData.pfs; }
    let getPersonalFoul = Number(getDefaultPF) + 1;
    update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
      pfs: getPersonalFoul,
    });
  }
  const handleStatMinus = (operationType, playerData, eventId) => {
    if(operationType === 'ftMade'){
      if(playerData.ft_made > 0){
        const getNewFtMade = Number(playerData.ft_made) - 1;
        const getNewFtAtt = Number(playerData.ft_att);
        const getNewFtPercentage = Math.ceil(( Number(getNewFtMade) / Number(getNewFtAtt)) * 100);
        const totalPoints = getNewFtMade + (playerData.fg_made * 2) + (playerData.three_pt * 3);
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          pts: totalPoints,
          ft_percentage: getNewFtPercentage,
          ft_made: getNewFtMade,
        });
      }
    }
    else if(operationType === 'ftAtt'){
      if( (playerData.ft_made < playerData.ft_att) && (playerData.ft_att > 1) ){
        const getNewFtAtt = Number(playerData.ft_att) - 1;
        const getNewFtPercentage = Math.ceil( (Number(playerData.ft_made) / Number(getNewFtAtt)) * 100 );
        if( Number.isNaN(getNewFtPercentage) ){ getNewFtPercentage = 0; }
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          ft_percentage: getNewFtPercentage,
          ft_att: getNewFtAtt,
        });
      }
      else if( (playerData.ft_made < playerData.ft_att) && (playerData.ft_att === 1) ){
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          ft_percentage: 0,
          ft_att: 0,
        });
      }
    }
    else if(operationType === 'fgMade'){
      if(playerData.fg_made > 0){
        const getNewTwoPtMade = Number(playerData.fg_made) - 1;
        const getTotalFgMade = Number(playerData.total_fg_made) - 1;
        const getNewFgPercentage = Math.ceil((( Number(getNewTwoPtMade) + Number(playerData.three_pt) ) / ( Number(playerData.fg_att) + Number(playerData.three_pt_att) )) * 100);
        const totalPoints = (playerData.ft_made) + (getNewTwoPtMade * 2) + (playerData.three_pt * 3);
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          pts: totalPoints,
          fg_percentage: getNewFgPercentage,
          fg_made: getNewTwoPtMade,
          total_fg_made: getTotalFgMade,
        });
      }
    }
    else if(operationType === 'fgAtt'){
      if( (playerData.fg_made < playerData.fg_att) && (playerData.fg_att > 1) ){
        const getNewTwoPtAtt = Number(playerData.fg_att) - 1;
        const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
        const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          fg_percentage: getNewFgPercentage,
          fg_att: getNewTwoPtAtt,
          total_fg_att: getTotalFgAtt,
        });
      }
      else if( (playerData.fg_made < playerData.fg_att) && (playerData.fg_att === 1) ){
        let playerThreePt = Number(playerData.three_pt);
        let playerThreePtAtt = Number(playerData.three_pt_att);
        if( Number(playerData.three_pt) === 0 ){ playerThreePt = 0; }
        if( Number(playerData.three_pt_att) === 0 ){ playerThreePtAtt = 0; }
        let getNewFgPercentage = Math.ceil( (playerThreePt / playerThreePtAtt) * 100 );
        if( playerThreePt === 0 & playerThreePtAtt === 0 ){ getNewFgPercentage = 0; }
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          fg_percentage: getNewFgPercentage,
          fg_att: 0,
          total_fg_att: 0,
        });
      }
    }
    else if(operationType === 'threePtMade'){
      if(playerData.three_pt > 0){
        const getNewThreePtMade = Number(playerData.three_pt) - 1;
        const getTotalFgMade = Number(playerData.total_fg_made) - 1;
        const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(getNewThreePtMade) ) / ( Number(playerData.fg_att) + Number(playerData.three_pt_att) )) * 100);
        const getNewThreePtPercentage = Math.ceil( ((Number(getNewThreePtMade)) / (Number(playerData.three_pt_att))) * 100);
        const totalPoints = (playerData.ft_made) + (getTotalFgMade * 2) + (getNewThreePtMade);
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          pts: totalPoints,
          fg_percentage: getNewFgPercentage,
          three_pt: getNewThreePtMade,
          three_pt_percentage: getNewThreePtPercentage,
          total_fg_made: getTotalFgMade,
        });
      }
    }
    else if(operationType === 'threePtAtt'){
      if( (playerData.three_pt < playerData.three_pt_att) && (playerData.three_pt_att > 1) ){
        const getNewThreePtAtt = Number(playerData.three_pt_att) - 1;
        const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
        const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(playerData.fg_made) + Number(getNewThreePtAtt) )) * 100);
        const getNewThreePtPercentage = Math.ceil( ((Number(playerData.three_pt)) / (Number(getNewThreePtAtt))) * 100);
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          fg_percentage: getNewFgPercentage,
          three_pt_att: getNewThreePtAtt,
          three_pt_percentage: getNewThreePtPercentage,
          total_fg_att: getTotalFgAtt,
        });
      }
      else if( (playerData.three_pt < playerData.three_pt_att) && (playerData.three_pt_att === 1) ){
        let playerFgMade = Number(playerData.fg_made);
        let playerFgAtt = Number(playerData.fg_att);

        if( Number(playerData.fg_made) === 0 ){ playerFgMade = 0; }
        if( Number(playerData.fg_att) === 0 ){ playerFgAtt = 0; }

        let getNewFgPercentage = Math.ceil( (playerFgMade / playerFgAtt) * 100 );
        if( playerFgMade === 0 & playerFgAtt === 0 ){ getNewFgPercentage = 0; }
        
        const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          fg_percentage: getNewFgPercentage,
          total_fg_att: getTotalFgAtt,
          three_pt_att: 0,
          three_pt_percentage: 0,
        });
      }
    }
    else if(operationType === 'personalFoul'){
      if(playerData.pfs > 0){
        let getNewPersonalFoul = playerData.pfs - 1;
        update(ref(realtimeDB, `/playerStats/${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`), {
          pfs: getNewPersonalFoul,
        });
      }   
    }
  };
  
  const handleFoul = (roster_type, op_type) => {
    const foulType = roster_type + '_tf';
    const teamFoul = document.getElementById(foulType);
    const currentFoul = parseInt(teamFoul.innerText); // Get current value and convert to number
    const foulLimit = 30;
    let oppTF = '';
    if(foulType === 'home_tf'){ oppTF =  'away_bonus'; }
    else if(foulType === 'away_tf'){ oppTF =  'home_bonus'; }
    let oppBonus = document.getElementById(oppTF);
    if(op_type === 'add'){
      teamFoul.innerText =  currentFoul + 1;
      if(currentFoul > foulLimit){ oppBonus.innerText = 'BONUS'; }else{ oppBonus.innerText = ''; }
    }else{
      teamFoul.innerText = (currentFoul === 0) ? 0 : currentFoul - 1;
      if(currentFoul < 30){ oppBonus.innerText = ''; }else{ oppBonus.innerText = 'BONUS'; }
    }
  }

  return (
    <>
    <div className='statkeep_container'>
      {/* Link user data */}
      { (activePlayerList[0]) ? 
        userList
          .filter(eventStatData => eventStatData[playerStatURL][activePlayerList[1]] !== undefined)
          .map((playerData) => {
            const getPlayerStats = playerData[playerStatURL][activePlayerList[1]][gameId][activePlayerList[0].id];
            return (
              <div key={getPlayerStats.player_id}>
                <div className="flex flex-row justify-evenly mt-1 mb-3">
                  <h3 className="font-urbanist text-white text-lg">PTS: {(getPlayerStats) ? getPlayerStats.pts : 0}</h3>
                  <h3 className="font-urbanist text-white text-lg">FG%: {(getPlayerStats) ? getPlayerStats.fg_percentage : 0}% ({(getPlayerStats) ? getPlayerStats.total_fg_made : 0}/{(getPlayerStats) ? getPlayerStats.total_fg_att : 0})</h3>
                  <h3 className="font-urbanist text-white text-lg">FT%: {(getPlayerStats) ? getPlayerStats.ft_percentage : 0}%</h3>
                  <h3 className="font-urbanist text-white text-lg">3PT%: {(getPlayerStats) ? getPlayerStats.three_pt_percentage : 0}%</h3>
                </div>
                <div id="statBtnContainer">
                  <div className="flex flex-row w-full justify-between mb-3">
                    <div className="w-6/12 flex flex-row items-center">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">2PT<span className="block">{((getPlayerStats) ? getPlayerStats.fg_made : 0) + '/' + ((getPlayerStats) ? getPlayerStats.fg_att : 0)}</span></h3>
                      <div className="w-6/12 flex flex-row justify-center">
                        <div className="w-8/12 flex flex-col mx-1">
                          <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => handleTwoPtMade(getPlayerStats, activePlayerList[1])}>Made</button>
                          <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleTwoPtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
                        </div>
                        <div className="w-4/12 flex flex-col">
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('fgMade', getPlayerStats, activePlayerList[1])}>-</button>
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('fgAtt', getPlayerStats, activePlayerList[1])}>-</button>
                        </div>
                      </div>
                    </div>
                    <div className="w-6/12 flex flex-row items-center mb-3">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">3PT<span className="block">{((getPlayerStats) ?getPlayerStats.three_pt : 0) + '/' + ((getPlayerStats) ? getPlayerStats.three_pt_att : 0)}</span></h3>
                      <div className="w-6/12 flex flex-row justify-center">
                        <div className="w-8/12 flex flex-col mx-1">
                          <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => handleThreePtMade(getPlayerStats, activePlayerList[1])}>Made</button>
                          <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleThreePtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
                        </div>
                        <div className="w-4/12 flex flex-col">
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('threePtMade', getPlayerStats, activePlayerList[1])}>-</button>
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('threePtAtt', getPlayerStats, activePlayerList[1])}>-</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row flex-wrap w-full justify-between mb-3">
                    <div className="w-6/12 flex flex-row items-center">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">FT<span className="block">{((getPlayerStats) ? getPlayerStats.ft_made : 0) + '/' + ((getPlayerStats) ? getPlayerStats.ft_att : 0)}</span></h3>
                      <div className="w-6/12 flex flex-row justify-center">
                        <div className="w-8/12 flex flex-col mx-1">
                          <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => handleFtMade(getPlayerStats, activePlayerList[1])}>Made</button>
                          <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleFtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
                        </div>
                        <div className="w-4/12 flex flex-col">
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('ftMade', getPlayerStats, activePlayerList[1])}>-</button>
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('ftAtt', getPlayerStats, activePlayerList[1])}>-</button>
                        </div>
                      </div>
                    </div>
                    <div className="w-6/12 flex flex-row items-center mb-3">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">FOULS<span className="block">{((getPlayerStats) ? (getPlayerStats.pfs !== undefined) ? getPlayerStats.pfs : 0 : 0)}</span></h3>
                      <div className="w-6/12 flex flex-row justify-center">
                        <div className="w-8/12 flex flex-col mx-1">
                          <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => {handlePersonalFoul(getPlayerStats, activePlayerList[1]); handleFoul(getPlayerStats.roster_type, 'add');} }>+</button>
                        </div>
                        <div className="w-4/12 flex flex-col">
                          <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => {handleStatMinus('personalFoul', getPlayerStats, activePlayerList[1]); handleFoul(getPlayerStats.roster_type, 'minus')} }>-</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row flex-wrap w-full justify-evenly mb-3">
                    <div className="w-6/12 flex flex-row items-center mb-2">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">AST<span className="block">{(getPlayerStats) ? getPlayerStats.ast : 0}</span></h3>
                      <div className="w-6/12 flex flex-col justify-center">
                        <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleAst(getPlayerStats, activePlayerList[1], 'add')}>+</button>
                        <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleAst(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
                      </div>
                    </div>
                    <div className="w-6/12 flex flex-row items-center mb-2">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">REB<span className="block">{(getPlayerStats) ? getPlayerStats.rbs : 0}</span></h3>
                      <div className="w-6/12 flex flex-col justify-center">
                        <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleReb(getPlayerStats, activePlayerList[1], 'add')}>+</button>
                        <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleReb(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
                      </div>
                    </div>
                   </div>
                  <div className="flex flex-row flex-wrap w-full justify-evenly items-center">
                    <div className="w-6/12 flex flex-row items-center">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">STL<span className="block">{(getPlayerStats) ? getPlayerStats.stl : 0}</span></h3>
                      <div className="w-6/12 flex flex-col justify-center">
                        <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleStl(getPlayerStats, activePlayerList[1], 'add')}>+</button>
                        <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleStl(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
                      </div>
                    </div>
                    <div className="w-6/12 flex flex-row items-center">
                      <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">BLK<span className="block">{(getPlayerStats) ? getPlayerStats.blk : 0}</span></h3>
                        <div className="w-6/12 flex flex-col justify-center">
                          <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleBlk(getPlayerStats, activePlayerList[1], 'add')}>+</button>
                          <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleBlk(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
                        </div>
                    </div>
                  </div>
                  <h1 className="font-urbanist text-white font-bold mt-3">
                      <span className="bg-white rounded-full text-black font-bold p-1 mr-3">{activePlayerList[0].j_num}</span>
                      {activePlayerList[0].name}
                  </h1>
                 </div>
              </div>
            );
          }) : '' 
      }
      {/* populate stat fields */}
    </div>
    </>
  )
}

export default StatKeep

// import React, { useState, useEffect } from 'react';
// import { realtimeDB } from '../firebaseConfig';
// import { set, ref, child, getDatabase, get } from 'firebase/database';
// import { useParams } from 'react-router-dom';
// import { getVar } from './customVariable';
// import { siteUrl } from './siteUrl';
// import { connect } from 'react-redux';
// import { setFetchTriggerCall } from '../reducers/actions'; // Check the correct path
// import { RosterResource, SavePlayerStats, FirestoreData } from '../logic/endpoints/endpointResource';

// const StatKeep = ({activePlayerList, fetchTriggerCall, setFetchTriggerCall}) => {
//   const [userList, setUserList] = useState([]);
//   const [fetchTrigger, setFetchTrigger] = useState(false);
//   const { gameId } = useParams(); // Get events parameter
//   const fullURL = window.location.href;
//   const playerStatURL = getVar('fb-rt-stat', fullURL);
//   const siteType = siteUrl(fullURL, 'expressJS');
//   const dataCollector = [siteType, playerStatURL, activePlayerList[1], gameId, setFetchTriggerCall, fetchTrigger]
  
//   RosterResource([playerStatURL, activePlayerList, gameId, fetchTrigger], setUserList, dataCollector);
  
//   // Check record. If it does not exist, create it
//   const database = getDatabase();
//   function checkRecordExists(event_id){
//     const dbRef = ref(database);
//     const path = `/playerStats/${playerStatURL}/${event_id}/${gameId}/${activePlayerList[0].id}/event_id`;
// //     const path = `/playerStats/${event_id}/${gameId}/${activePlayerList[0].id}`;
//     get(child(dbRef, path)).then((snapshot) => {
//       if (!snapshot.exists()){
//         // Create player and initialize stats
//         const playerId = activePlayerList[0].id;
// //         const statPoint = 0;
//         set(ref(realtimeDB, `/playerStats/${playerStatURL}/${activePlayerList[1]}/${gameId}/${playerId}`), {
//           event_id: activePlayerList[1],
//           player_id: playerId,
//           player_status: 'active',
//           game_id: gameId,
//           ft_made: 0,
//           ft_att: 0,
//           fg_made: 0,
//           fg_att: 0,
//           total_fg_made: 0,
//           total_fg_att: 0,
//           three_pt: 0,
//           three_pt_att: 0,
//           pts: 0,
//           fg_percentage: 0,
//           ft_percentage: 0,
//           three_pt_percentage: 0,
//           rbs: 0,
//           ast: 0,
//           stl: 0,
//           blk: 0,
//           start_time: 0,
//           stop_time: 0,
//           time_pl: 0,
//           roster_status: null,
//           roster_type: activePlayerList[2],
//         });
//       }
//     }).catch((error) => {
//       console.error("Error reading Firebase Database", error);
//     });
//   }
//   // Check record every time new player is selected
//   if(typeof(activePlayerList[0]) !== 'undefined'){ checkRecordExists(activePlayerList[1]); }
  
//   const batchUpdate = (updates, opType) => {
//     const updatesRef = {}; // Object to store updates for different locations

//     // Group updates by their respective locations
//     for (const [location, toUpdate] of Object.entries(updates)) {
//       if (!updatesRef[location]) {
//         updatesRef[location] = {};
//       }
//       Object.assign(updatesRef[location], toUpdate);
//     }

//     // Perform multi-location update
//     for (const [location, toUpdate] of Object.entries(updatesRef)) {
//   //     const playerRef = ref(realtimeDB, location);
//   //     update(playerRef, toUpdate);
//       SavePlayerStats(siteType, location, toUpdate);
//     }
//     if(opType === true){ setFetchTrigger(prevState => !prevState); }
//   };
  
// const handleFtMade = (playerData, eventId) => {
//   const getNewFtMade = Number(playerData.ft_made) + 1;
//   const getNewFtAtt = Number(playerData.ft_att) + 1;
//   const getNewFtPercentage = Math.ceil((getNewFtMade / getNewFtAtt) * 100);
//   const totalPoints = getNewFtMade + playerData.fg_made * 2 + playerData.three_pt * 3;

//   const updates = {
//     [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//       pts: totalPoints,
//       ft_percentage: getNewFtPercentage,
//       ft_made: getNewFtMade,
//       ft_att: getNewFtAtt,
//     }
//   };

//   batchUpdate(updates, true);
// };
  
//   const handleFtAtt = (playerData, eventId) => {
//     const getNewFtAtt = Number(playerData.ft_att) + 1;
//     const getNewFtPercentage = Math.ceil(( Number(playerData.ft_made) / Number(getNewFtAtt)) * 100);
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         ft_percentage: getNewFtPercentage,
//         ft_att: getNewFtAtt,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleTwoPtMade = (playerData, eventId) => {
//     const getNewTwoPtMade = Number(playerData.fg_made) + 1;
//     const getNewTwoPtAtt = Number(playerData.fg_att) + 1;
//     const getNewFgPercentage = Math.ceil((( Number(getNewTwoPtMade) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
//     const totalPoints = (playerData.ft_made) + (getNewTwoPtMade * 2) + (playerData.three_pt * 3);
//     const totalFgMade =  getNewTwoPtMade + playerData.three_pt;
//     const totalFgAtt =  getNewTwoPtAtt + playerData.three_pt_att;
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         pts: totalPoints,
//         fg_percentage: getNewFgPercentage,
//         fg_made: getNewTwoPtMade,
//         fg_att: getNewTwoPtAtt,
//         total_fg_made: totalFgMade,
//         total_fg_att: totalFgAtt,
//         roster_type: activePlayerList[2],
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleTwoPtAtt = (playerData, eventId) => {
//     const getNewTwoPtAtt = Number(playerData.fg_att) + 1;
//     const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
//     const totalFgAtt =  getNewTwoPtAtt + playerData.three_pt_att;
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         fg_percentage: getNewFgPercentage,
//         fg_att: getNewTwoPtAtt,
//         total_fg_att: totalFgAtt,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleThreePtMade = (playerData, eventId) => {
//     const getNewThreePtMade = Number(playerData.three_pt) + 1;
//     const getNewThreePtAtt = Number(playerData.three_pt_att) + 1;
//     const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(getNewThreePtMade) ) / ( Number(playerData.fg_att) + Number(getNewThreePtAtt) )) * 100);
//     const getNewThreePtPercentage = Math.ceil( ((Number(getNewThreePtMade)) / (Number(getNewThreePtAtt))) * 100);
//     const totalPoints = (playerData.ft_made) + (playerData.fg_made * 2) + (getNewThreePtMade * 3);
//     const totalFgMade =  getNewThreePtMade + playerData.fg_made;
//     const totalFgAtt =  getNewThreePtAtt + playerData.fg_att;
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         pts: totalPoints,
//         fg_percentage: getNewFgPercentage,
//         three_pt: getNewThreePtMade,
//         three_pt_att: getNewThreePtAtt,
//         three_pt_percentage: getNewThreePtPercentage,
//         total_fg_made: totalFgMade,
//         total_fg_att: totalFgAtt,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleThreePtAtt = (playerData, eventId) => {
//     const getNewThreePtAtt = Number(playerData.three_pt_att) + 1;
//     const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(playerData.fg_att) + Number(getNewThreePtAtt) )) * 100);
//     const getNewThreePtPercentage = Math.ceil( ((Number(playerData.three_pt)) / (Number(getNewThreePtAtt))) * 100);
//     const totalFgAtt =  getNewThreePtAtt + playerData.fg_att;
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         fg_percentage: getNewFgPercentage,
//         three_pt_att: getNewThreePtAtt,
//         three_pt_percentage: getNewThreePtPercentage,
//         total_fg_att: totalFgAtt,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleAst = (playerData, eventId, operationType) => {
//     let getNewAst = Number(playerData.ast) + 1;
//     if(operationType === 'minus'){
//       getNewAst = Number(playerData.ast) - 1;
//       if(getNewAst < 0){ getNewAst = 0; }
//     }
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         ast: getNewAst,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleReb = (playerData, eventId, operationType) => {
//     let getNewReb = Number(playerData.rbs) + 1;
//     if(operationType === 'minus'){
//       getNewReb = Number(playerData.rbs) - 1;
//       if(getNewReb < 0){ getNewReb = 0; }
//     }
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         rbs: getNewReb,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleStl = (playerData, eventId, operationType) => {
//     let getNewStl = Number(playerData.stl) + 1;
//     if(operationType === 'minus'){
//       getNewStl = Number(playerData.stl) - 1;
//       if(getNewStl < 0){ getNewStl = 0; }
//     }
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         stl: getNewStl,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handleBlk = (playerData, eventId, operationType) => {
//     let getNewBlk = Number(playerData.blk) + 1;
//     if(operationType === 'minus'){
//       getNewBlk = Number(playerData.blk) - 1;
//       if(getNewBlk < 0){ getNewBlk = 0; }
//     }
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         blk: getNewBlk,
//       }
//     };

//     batchUpdate(updates, true);
//   };
//   const handlePersonalFoul = (playerData, eventId, operationTyp) => {
//     let getDefaultPF = 0;
//     if(playerData.pfs === undefined){ getDefaultPF = 0; }else{ getDefaultPF = playerData.pfs; }
//     let getPersonalFoul = Number(getDefaultPF) + 1;
    
//     const updates = {
//       [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//         pfs: getPersonalFoul,
//       }
//     };

//     batchUpdate(updates, true);
//   }
//   const handleStatMinus = (operationType, playerData, eventId) => {
//     if(operationType === 'ftMade'){
//       if(playerData.ft_made > 0){
//         const getNewFtMade = Number(playerData.ft_made) - 1;
//         const getNewFtAtt = Number(playerData.ft_att);
//         let getNewFtPercentage = Math.ceil(( Number(getNewFtMade) / Number(getNewFtAtt)) * 100);
//         const totalPoints = getNewFtMade + (playerData.fg_made * 2) + (playerData.three_pt * 3);
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             pts: totalPoints,
//             ft_percentage: getNewFtPercentage,
//             ft_made: getNewFtMade,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'ftAtt'){
//       if( (playerData.ft_made < playerData.ft_att) && (playerData.ft_att > 1) ){
//         const getNewFtAtt = Number(playerData.ft_att) - 1;
//         let getNewFtPercentage = Math.ceil( (Number(playerData.ft_made) / Number(getNewFtAtt)) * 100 );
//         if( Number.isNaN(getNewFtPercentage) ){ getNewFtPercentage = 0; }
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             ft_percentage: getNewFtPercentage,
//             ft_att: getNewFtAtt,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//       else if( (playerData.ft_made < playerData.ft_att) && (playerData.ft_att === 1) ){
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             ft_percentage: 0,
//             ft_att: 0,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'fgMade'){
//       if(playerData.fg_made > 0){
//         const getNewTwoPtMade = Number(playerData.fg_made) - 1;
//         const getTotalFgMade = Number(playerData.total_fg_made) - 1;
//         const getNewFgPercentage = Math.ceil((( Number(getNewTwoPtMade) + Number(playerData.three_pt) ) / ( Number(playerData.fg_att) + Number(playerData.three_pt_att) )) * 100);
//         const totalPoints = (playerData.ft_made) + (getNewTwoPtMade * 2) + (playerData.three_pt * 3);
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             pts: totalPoints,
//             fg_percentage: getNewFgPercentage,
//             fg_made: getNewTwoPtMade,
//             total_fg_made: getTotalFgMade,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'fgAtt'){
//       if( (playerData.fg_made < playerData.fg_att) && (playerData.fg_att > 1) ){
//         const getNewTwoPtAtt = Number(playerData.fg_att) - 1;
//         const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
//         const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(getNewTwoPtAtt) + Number(playerData.three_pt_att) )) * 100);
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             fg_percentage: getNewFgPercentage,
//             fg_att: getNewTwoPtAtt,
//             total_fg_att: getTotalFgAtt,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//       else if( (playerData.fg_made < playerData.fg_att) && (playerData.fg_att === 1) ){
//         let playerThreePt = Number(playerData.three_pt);
//         let playerThreePtAtt = Number(playerData.three_pt_att);
//         if( Number(playerData.three_pt) === 0 ){ playerThreePt = 0; }
//         if( Number(playerData.three_pt_att) === 0 ){ playerThreePtAtt = 0; }
//         let getNewFgPercentage = Math.ceil( (playerThreePt / playerThreePtAtt) * 100 );
//         if( playerThreePt === 0 & playerThreePtAtt === 0 ){ getNewFgPercentage = 0; }
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             fg_percentage: getNewFgPercentage,
//             fg_att: 0,
//             total_fg_att: 0,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'threePtMade'){
//       if(playerData.three_pt > 0){
//         const getNewThreePtMade = Number(playerData.three_pt) - 1;
//         const getTotalFgMade = Number(playerData.total_fg_made) - 1;
//         const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(getNewThreePtMade) ) / ( Number(playerData.fg_att) + Number(playerData.three_pt_att) )) * 100);
//         const getNewThreePtPercentage = Math.ceil( ((Number(getNewThreePtMade)) / (Number(playerData.three_pt_att))) * 100);
//         const totalPoints = (playerData.ft_made) + (getTotalFgMade * 2) + (getNewThreePtMade);
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             pts: totalPoints,
//             fg_percentage: getNewFgPercentage,
//             three_pt: getNewThreePtMade,
//             three_pt_percentage: getNewThreePtPercentage,
//             total_fg_made: getTotalFgMade,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'threePtAtt'){
//       if( (playerData.three_pt < playerData.three_pt_att) && (playerData.three_pt_att > 1) ){
//         const getNewThreePtAtt = Number(playerData.three_pt_att) - 1;
//         const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
//         const getNewFgPercentage = Math.ceil((( Number(playerData.fg_made) + Number(playerData.three_pt) ) / ( Number(playerData.fg_made) + Number(getNewThreePtAtt) )) * 100);
//         const getNewThreePtPercentage = Math.ceil( ((Number(playerData.three_pt)) / (Number(getNewThreePtAtt))) * 100);
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             fg_percentage: getNewFgPercentage,
//             three_pt_att: getNewThreePtAtt,
//             three_pt_percentage: getNewThreePtPercentage,
//             total_fg_att: getTotalFgAtt,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//       else if( (playerData.three_pt < playerData.three_pt_att) && (playerData.three_pt_att === 1) ){
//         let playerFgMade = Number(playerData.fg_made);
//         let playerFgAtt = Number(playerData.fg_att);

//         if( Number(playerData.fg_made) === 0 ){ playerFgMade = 0; }
//         if( Number(playerData.fg_att) === 0 ){ playerFgAtt = 0; }

//         let getNewFgPercentage = Math.ceil( (playerFgMade / playerFgAtt) * 100 );
//         if( playerFgMade === 0 & playerFgAtt === 0 ){ getNewFgPercentage = 0; }
        
//         const getTotalFgAtt = Number(playerData.total_fg_att) - 1;
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             fg_percentage: getNewFgPercentage,
//             total_fg_att: getTotalFgAtt,
//             three_pt_att: 0,
//             three_pt_percentage: 0,
//           }
//         };

//         batchUpdate(updates, false);
//       }
//     }
//     else if(operationType === 'personalFoul'){
//       if(playerData.pfs > 0){
//         let getNewPersonalFoul = playerData.pfs - 1;
        
//         const updates = {
//           [`${playerStatURL}/${eventId}/${playerData.game_id}/${playerData.player_id}`]: {
//             pfs: getNewPersonalFoul,
//           }
//         };

//         batchUpdate(updates, false);
//       }   
//     }
//     setFetchTrigger(prevState => !prevState);
//   };
  
//   const handleFoul = (roster_type, op_type) => {
//     const foulType = roster_type + '_tf';
//     const teamFoul = document.getElementById(foulType);
//     const currentFoul = parseInt(teamFoul.innerText); // Get current value and convert to number
//     const foulLimit = 30;
//     let oppTF = '';
//     if(foulType === 'home_tf'){ oppTF =  'away_bonus'; }
//     else if(foulType === 'away_tf'){ oppTF =  'home_bonus'; }
//     let oppBonus = document.getElementById(oppTF);
//     if(op_type === 'add'){
//       teamFoul.innerText =  currentFoul + 1;
//       if(currentFoul > foulLimit){ oppBonus.innerText = 'BONUS'; }else{ oppBonus.innerText = ''; }
//     }else{
//       teamFoul.innerText = (currentFoul === 0) ? 0 : currentFoul - 1;
//       if(currentFoul < 30){ oppBonus.innerText = ''; }else{ oppBonus.innerText = 'BONUS'; }
//     }
//   }

//   return (
//     <>
//     <div className='statkeep_container'>
//       {/* Link user data */}
//       { (activePlayerList[0]) ? 
//         userList
//           .filter(eventStatData => eventStatData !== undefined && eventStatData.player_id === activePlayerList[0].id)
//           .map((playerData) => {
//             const getPlayerStats = playerData;
//             return (
//               <div key={`player_${getPlayerStats.player_id}`} className="player-stats-container">
//                 <div className="flex flex-row justify-evenly mt-1 mb-3">
//                   <h3 className="font-urbanist text-white text-lg">PTS: {(getPlayerStats) ? getPlayerStats.pts : 0}</h3>
//                   <h3 className="font-urbanist text-white text-lg">FG%: {(getPlayerStats) ? getPlayerStats.fg_percentage : 0}% ({(getPlayerStats) ? getPlayerStats.total_fg_made : 0}/{(getPlayerStats) ? getPlayerStats.total_fg_att : 0})</h3>
//                   <h3 className="font-urbanist text-white text-lg">FT%: {(getPlayerStats) ? getPlayerStats.ft_percentage : 0}%</h3>
//                   <h3 className="font-urbanist text-white text-lg">3PT%: {(getPlayerStats) ? getPlayerStats.three_pt_percentage : 0}%</h3>
//                 </div>
//                 <div id="statBtnContainer">
//                   <div className="flex flex-row w-full justify-between mb-3">
//                     <div className="w-6/12 flex flex-row items-center">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">2PT<span className="block">{((getPlayerStats) ? getPlayerStats.fg_made : 0) + '/' + ((getPlayerStats) ? getPlayerStats.fg_att : 0)}</span></h3>
//                       <div className="w-6/12 flex flex-row justify-center">
//                         <div className="w-8/12 flex flex-col mx-1">
//                           <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => { handleTwoPtMade(getPlayerStats, activePlayerList[1]); } }>Made</button>
//                           <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleTwoPtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
//                         </div>
//                         <div className="w-4/12 flex flex-col">
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('fgMade', getPlayerStats, activePlayerList[1])}>-</button>
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('fgAtt', getPlayerStats, activePlayerList[1])}>-</button>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="w-6/12 flex flex-row items-center mb-3">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">3PT<span className="block">{((getPlayerStats) ?getPlayerStats.three_pt : 0) + '/' + ((getPlayerStats) ? getPlayerStats.three_pt_att : 0)}</span></h3>
//                       <div className="w-6/12 flex flex-row justify-center">
//                         <div className="w-8/12 flex flex-col mx-1">
//                           <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => handleThreePtMade(getPlayerStats, activePlayerList[1])}>Made</button>
//                           <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleThreePtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
//                         </div>
//                         <div className="w-4/12 flex flex-col">
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('threePtMade', getPlayerStats, activePlayerList[1])}>-</button>
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('threePtAtt', getPlayerStats, activePlayerList[1])}>-</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex flex-row flex-wrap w-full justify-between mb-3">
//                     <div className="w-6/12 flex flex-row items-center">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">FT<span className="block">{((getPlayerStats) ? getPlayerStats.ft_made : 0) + '/' + ((getPlayerStats) ? getPlayerStats.ft_att : 0)}</span></h3>
//                       <div className="w-6/12 flex flex-row justify-center">
//                         <div className="w-8/12 flex flex-col mx-1">
//                           <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => handleFtMade(getPlayerStats, activePlayerList[1])}>Made</button>
//                           <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red mb-2" onClick={() => handleFtAtt(getPlayerStats, activePlayerList[1])}>Miss</button>
//                         </div>
//                         <div className="w-4/12 flex flex-col">
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => handleStatMinus('ftMade', getPlayerStats, activePlayerList[1])}>-</button>
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 mt-1 rounded-lg mr-1" onClick={() => handleStatMinus('ftAtt', getPlayerStats, activePlayerList[1])}>-</button>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="w-6/12 flex flex-row items-center mb-3">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">FOULS<span className="block">{((getPlayerStats) ? (getPlayerStats.pfs !== undefined) ? getPlayerStats.pfs : 0 : 0)}</span></h3>
//                       <div className="w-6/12 flex flex-row justify-center">
//                         <div className="w-8/12 flex flex-col mx-1">
//                           <button className="font-urbanist bg-black text-white text-base border-1 p-1 rounded-lg mb-1" onClick={() => {handlePersonalFoul(getPlayerStats, activePlayerList[1]); handleFoul(getPlayerStats.roster_type, 'add');} }>+</button>
//                         </div>
//                         <div className="w-4/12 flex flex-col">
//                           <button className="flex justify-center font-urbanist bg-black text-white text-base border-1 border-gray-500 px-3 py-1 rounded-lg mr-1" onClick={() => {handleStatMinus('personalFoul', getPlayerStats, activePlayerList[1]); handleFoul(getPlayerStats.roster_type, 'minus')} }>-</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex flex-row flex-wrap w-full justify-evenly mb-3">
//                     <div className="w-6/12 flex flex-row items-center mb-2">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">AST<span className="block">{(getPlayerStats) ? getPlayerStats.ast : 0}</span></h3>
//                       <div className="w-6/12 flex flex-col justify-center">
//                         <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleAst(getPlayerStats, activePlayerList[1], 'add')}>+</button>
//                         <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleAst(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
//                       </div>
//                     </div>
//                     <div className="w-6/12 flex flex-row items-center mb-2">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">REB<span className="block">{(getPlayerStats) ? getPlayerStats.rbs : 0}</span></h3>
//                       <div className="w-6/12 flex flex-col justify-center">
//                         <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleReb(getPlayerStats, activePlayerList[1], 'add')}>+</button>
//                         <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleReb(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
//                       </div>
//                     </div>
//                    </div>
//                   <div className="flex flex-row flex-wrap w-full justify-evenly items-center">
//                     <div className="w-6/12 flex flex-row items-center">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">STL<span className="block">{(getPlayerStats) ? getPlayerStats.stl : 0}</span></h3>
//                       <div className="w-6/12 flex flex-col justify-center">
//                         <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleStl(getPlayerStats, activePlayerList[1], 'add')}>+</button>
//                         <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleStl(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
//                       </div>
//                     </div>
//                     <div className="w-6/12 flex flex-row items-center">
//                       <h3 className="w-3/12 font-urbanist text-white text-xl font-bold mr-3">BLK<span className="block">{(getPlayerStats) ? getPlayerStats.blk : 0}</span></h3>
//                         <div className="w-6/12 flex flex-col justify-center">
//                           <button className="font-urbanist bg-black text-white text-base border-1 p-1 px-3 rounded-lg mb-1" onClick={() => handleBlk(getPlayerStats, activePlayerList[1], 'add')}>+</button>
//                           <button className="font-urbanist text-red-dark text-base p-1 px-3 rounded-lg bg-red" onClick={() => handleBlk(getPlayerStats, activePlayerList[1], 'minus')}>-</button>
//                         </div>
//                     </div>
//                   </div>
//                   <h1 className="font-urbanist text-white font-bold mt-3">
//                       <span className="bg-white rounded-full text-black font-bold p-1 mr-3">{activePlayerList[0].j_num}</span>
//                       {activePlayerList[0].name}
//                   </h1>
//                  </div>
//               </div>
//             );
//           }) : '' 
//       }
//       {/* populate stat fields */}
//     </div>
//     </>
//   )
// }

// const mapStateToProps = state => ({
//   fetchTriggerCall: state.fetchTriggerCall
// });

// // Ensure setFetchTriggerCall is included in mapDispatchToProps
// const mapDispatchToProps = {
//   setFetchTriggerCall
// };

// export default connect(mapStateToProps, mapDispatchToProps)(StatKeep);