import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginPage = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(''); // State for error message
  const navigate = useNavigate();
  const eventPath = process.env.REACT_APP_PAGE_TYPE_EVENT + '/home';

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        navigate('/' + eventPath);
      })
      .catch((error) => {
        setError('Invalid email or password. Please try again.'); // Set error message
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container className="mt-5 w-6/12">
      <Form onSubmit={handleSubmit} className="bg-white p-5 rounded-lg">
        <h2 className="font-urbanist text-black font-bold">Stats Platform</h2>
        <p className="text-black text-base mb-4">Please enter your login credentials to continue</p>
        {error && (
          <p className="text-[#ff0505] text-sm mb-4 italic">{error}</p> // Styled error message
        )}
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
            className="border-x-0 border-t-0 rounded-none mb-2"
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="password-input-group">
          <div className="password-input-container">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="border-x-0 border-t-0 rounded-none mb-4 password-input"
            />
            {password && (
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                onClick={togglePasswordVisibility}
                className="show-hide-icon"
              />
            )}
          </div>
          <Button className="bg-black text-white px-8 rounded-med shadow-sm border-none hover:bg-white" type="submit">Login</Button>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default LoginPage;