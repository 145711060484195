import React, { useEffect, useState } from 'react';
import { siteUrl } from '../../utils/siteUrl';
import { realtimeDB } from '../../firebaseConfig';
import { onValue, ref } from 'firebase/database';
import { getVar } from '../../utils/customVariable';
// import useBlockNavigation from '../useBlockNavigation';

const PostPlayerGameData = (props) => {

  const [playerStats, setPlayerStats] = useState([]);
  const [gameScore, setGameScore] = useState([]);
  const [exposureGame, setExposureGame] = useState();
  const [eventId, setEventId] = useState();
  const [isGameResultSaved, setIsGameResultSaved] = useState(false);
//   useBlockNavigation(!isGameResultSaved);
  const fullURL = window.location.href;
  const getStatURL = getVar('fb-rt-stat', fullURL);
  // Get player stats
  useEffect(() => {
    const getGameData = (props.gameData[0]) ? props.gameData[0] : '';
    if(getGameData.event_id !== undefined){
      onValue(ref(realtimeDB), (snapshot) => {
        setPlayerStats([]);
        const userData = snapshot.val();
        if(typeof(userData['playerStats'][getStatURL][getGameData.event_id]) !== 'undefined'){
          const homePlayers = Object.values(userData['playerStats'][getStatURL][getGameData.event_id][getGameData.id]).filter(players => players.roster_type === 'home' && players.event_id === getGameData.event_id && players.game_id === getGameData.id);
          const awayPlayers = Object.values(userData['playerStats'][getStatURL][getGameData.event_id][getGameData.id]).filter(players => players.roster_type === 'away' && players.event_id === getGameData.event_id && players.game_id === getGameData.id);
          const activePlayers = Object.values(userData['playerStats'][getStatURL][getGameData.event_id][getGameData.id]).filter(activePlayers => activePlayers.event_id === getGameData.event_id && activePlayers.game_id === getGameData.id);
          const playerHomeScore = homePlayers.reduce((accumulator, currentItem) => accumulator + currentItem.pts, 0);
          const playerAwayScore = awayPlayers.reduce((accumulator, currentItem) => accumulator + currentItem.pts, 0);
          setGameScore({'home_score': playerHomeScore, 'away_score': playerAwayScore});
          // Stats data: player id, event id, game id and stats
          const playerGameStats = [];
          Object.keys(activePlayers).forEach(key => playerGameStats.push( {"player_id": activePlayers[key]['player_id'], "event_id": activePlayers[key]['event_id'], "game_id": activePlayers[key]['game_id'], "stats": { "ast": activePlayers[key]['ast'], "rbs": activePlayers[key]['rbs'], "stl": activePlayers[key]['stl'], "blk": activePlayers[key]['blk'], "three_pt": activePlayers[key]['three_pt'], "three_pt_att": activePlayers[key]['three_pt_att'], "three_pt_percentage": activePlayers[key]['three_pt_percentage'], "ft_made": activePlayers[key]['ft_made'], "ft_att": activePlayers[key]['ft_att'], "ft_percentage": activePlayers[key]['ft_percentage'], "fg_made": activePlayers[key]['fg_made'], "fg_att": activePlayers[key]['fg_att'], "fg_percentage": activePlayers[key]['fg_percentage'], "pts": activePlayers[key]['pts'] }} ));
          setPlayerStats(playerGameStats);
        }
      });
      setExposureGame(getGameData['exposure_game_id']);
      setEventId(getGameData.event_id);
    }
  }, [props]);

  const playerStatURL = siteUrl(fullURL, 'expressJS') + '/post/api/v1/post_player_stats';
  const exposureAPI = siteUrl(fullURL, 'expressJS') + '/post/api/v1/exposure';
  const gameScoreAPI = siteUrl(fullURL, 'expressJS') + '/post/api/v1/game_score';
  const secretKeys = process.env.REACT_APP_REQUEST_SECRET_KEY;
  const submitPlayerStats = async () => {
    // Save players' stats
    try {
      const response = await fetch(playerStatURL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${secretKeys}`
        },
        body: JSON.stringify({
          // Stats data: player id, event id, game id and stats
          player_stats: playerStats
        })
      });
      const data = await response.json();
      if(data.message !== false){
        alert('Players\' stats are updated successfully.');
      }else{
        alert('Issue updating players\' stats. Please contact administrator.');
      }
    } catch (error) {
      console.error("Error posting data", error);
      alert('Issue updating players\' stats. Please contact administrator.');
    }
    // Push game scores to Exposure
    try {
      const response = await fetch(exposureAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${secretKeys}`
        },
        body: JSON.stringify({
          game_id: exposureGame,
          event_id: eventId,
          home_score: gameScore['home_score'],
          away_score: gameScore['away_score']
        })
      });
      const data = await response.json();
      console.log(data.response);
      if(data.response.code === 200){
        alert('Exposure game scores are updated successfully.')
      }else{
        alert('Issue updating Exposure game scores. Please contact administrator.');
      }
      setIsGameResultSaved(true);
    } catch (error) {
      console.error("Error posting data", error);
      alert('Issue updating Exposure game scores. Please contact administrator.');
    }
    // Update game scores on our DB
    try {
      const response = await fetch(gameScoreAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${secretKeys}`
        },
        body: JSON.stringify({
          game_id: exposureGame,
          home_score: gameScore['home_score'],
          away_score: gameScore['away_score']
        })
      });
      const data = await response.status;
      if(data === 200){
        console.log('Game scores are updated successfully on our DB.')
      }else{
        console.log('Issue updating game scores on our DB. Please contact administrator.');
      }
    } catch (error) {
      console.error("Error posting data", error);
      console.log('Issue updating game scores on our DB. Please contact administrator.');
    }
  };

  return (
    <div>
      <button className="text-white bg-black border-1 p-1 rounded-md text-base" onClick={submitPlayerStats}>Save Game Results</button>
    </div>
  )
}

export default PostPlayerGameData
