import React from 'react';

function formatDate(date){
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  let hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  
  return `${months[monthIndex]} ${day}, ${year} - ${hour}:${minuteFormatted}${ampm}`;
}

function CustomFormattedDate(dateString){
  const date = new Date(dateString);
  const formattedDate = formatDate(date);
  return formattedDate;
 }

export default CustomFormattedDate;
