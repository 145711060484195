import React, { useState } from 'react';
import RequestData from '../logic/search_data/searchResult';
import GetGame from '../components/getGame';
import { Link, useParams } from 'react-router-dom';

const LiveSearch = ({searchType}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(true);
  const data = RequestData(searchType);
  const filteredData = data.filter(item => item.event_name.toLowerCase().includes(searchTerm.toLowerCase()));
  const MAX_RESULTS = 10;
  let displayedData = filteredData.slice(0, MAX_RESULTS);
  const { requestId } = useParams(); // Get events parameter
  const getEvent = data.filter(events => events.event_id === requestId);
  
  const [clickLoaded, setClickLoaded] = useState(false);

  const handleClear = () => { 
    document.querySelector('#courtSelector').style.display='flex';
      setSearchTerm(''); 
    
      setTimeout(() => {
    const allRows = document.querySelectorAll('.court_list tbody tr');
//         console.log(allRows.length);
      }, 2000);

    
  }
  const handleSearchTerm = (e) => {
    document.querySelector('#courtSelector').style.display='none';
    setSearchTerm(e.target.value);
  }
    
  return (
    <>
    <div className="w-9/12 ">
      <input
        type="text"
        placeholder="Event name"
        value={searchTerm}
        onChange={(e) => handleSearchTerm(e)}
        className="text-black w-full rounded-full p-2"
      />
      {(searchTerm && displayedData.length > 0) && (
        <ul className="absolute w-full bg-white text-left">
          {displayedData.map((item, index) => (
            <li key={index} onClick={handleClear}><Link to={`/${process.env.REACT_APP_PAGE_TYPE_EVENT}/${item.event_id}`} className="block text-black p-2 border-b-2 border-b-gray w-full">{item.event_name}</Link></li>
          ))}
        </ul>
      )}
    </div>
    <GetGame eventId={requestId} eventName={(getEvent[0] !== undefined) ? getEvent[0].event_name : ''}/>
    </>
  );
};

export default LiveSearch;