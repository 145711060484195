import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CheckInternetConnection = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  useEffect(() => {
    // Handler to call when connection status changes
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    // Add event listeners for online and offline events
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    // Navigate to a different route or show a message when there is no internet
    if (!isOnline) {
      // Show alert or navigate to a specific route
      alert('You are offline');
    }
  }, [isOnline, navigate]);

  return (
    <div className="my-1">
      {isOnline ? (
        <p className="text-sm font-bold text-green mt-2">Online</p>
      ) : (
        <p className="text-sm font-bold text-red-dark">Offline. Please check your internet connection.</p>
      )}
    </div>
  );
};

export default CheckInternetConnection;