import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const ProtectedRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if(authUser){
        // User is signed in
        setUser(authUser);
      }else{
        // No user is signed in
        setUser(null);
        navigate('/login');
      }
      setLoading(false);
    });

    return () => {
      unsubscribe(); // Cleanup on component unmount
    };
  }, []);

  return <Outlet />;
};

export default ProtectedRoute;
