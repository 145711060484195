import React from 'react';
import LiveSearch from '../utils/searchBox';
// import { db } from '../firebaseConfig';

const EventCourt = () => {
  const pageType = window.location.pathname.split('/');
  const getSearchDataType = pageType[1] || null; // Gets the first segment after the domain
  
//   const fetchData = async () => {
//   try {
//     const querySnapshot = await db.collection('apple').get();
//     querySnapshot.forEach(doc => {
//       console.log(doc.id, ' => ', doc.data());
//     });
//   } catch (error) {
//     console.error('Error fetching data:', error);
//   }
// };

// fetchData();
  
//   console.log(fetchData());
//   console.log(db);
  
  return (
    <>
    <div className="flex flex-row flex-wrap items-center w-full p-3 bg-black">
      <h1 className="w-3/12">Stats Platform</h1>
      <LiveSearch searchType={getSearchDataType} />
    </div>
    </>
  );
};

export default EventCourt;