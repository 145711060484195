import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';

function SimpleTable({ data, columns }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  
  let desiredLocation;
  let isMultipleLocations;
  
  const setDesiredLocation = (event) => {
   desiredLocation = event.currentTarget.textContent;
  }
  
  
  const setIsMultipleLocations = (bool) => {
    isMultipleLocations = bool;
  }
  
//   clear buttons every page/event load or redirect
  const clearCourtSelector = () => {
    document.querySelectorAll('#courtSelector button[data-button-type="courtBtn"]').forEach(btn => btn.remove());
  }
  const clearLocationSelector = () => {
    document.querySelectorAll('#locationSelector button[data-button-type="locationBtn"]').forEach(btn => btn.remove());
  }
  
  clearLocationSelector();
  clearCourtSelector();
  
  const clearCourts = () => {
    const allRows = document.querySelectorAll('.court_list tbody tr');
    allRows.forEach(row => {
      row.classList.add('hidden');
    })
  }
  
  
  const clearLocations = () => {
    const allRows = document.querySelectorAll('.court_list tbody tr');
    allRows.forEach(row => {
      row.classList.add('hidden');
    })
  }
  
  
  const clearCourtSelections = () => {
    const allBtns = document.querySelectorAll('#courtSelector button');
    allBtns.forEach(btn => {
     btn.classList.remove('bg-white','text-black','p-1');
    })
  }
  
  const clearLocationSelections = () => {
    const allLocationBtns = document.querySelectorAll('#locationSelector button');
    allLocationBtns.forEach(btn => {
     btn.classList.remove('bg-white','text-black');
     btn.classList.add('text-white');
    })
  }
  
  const showCourts = (event) => {
     clearCourts();
     clearCourtSelections();
     let courtSelection = event.currentTarget.textContent;
     let matchingCourts;
     event.currentTarget.classList.add('bg-white','text-black','p-1');
     
     if(isMultipleLocations == true) {
       matchingCourts = document.querySelectorAll(`[data-court='${courtSelection}'][data-location='${desiredLocation}']`)
     } else {
       matchingCourts = document.querySelectorAll(`[data-court='${courtSelection}']`)
     }
     matchingCourts.forEach(court => {
       court.classList.remove('hidden');
     })
  }
  
  const showLocations = (event) => {
     clearLocations();
     clearLocationSelections();
     setDesiredLocation(event);
     let locationSelection = event.currentTarget.textContent;
    
//     button style change
     event.currentTarget.classList.remove('text-white');
     event.currentTarget.classList.add('bg-white','text-black');
     
     let matchingLocations = document.querySelectorAll(`[data-location='${locationSelection}']`)
     matchingLocations.forEach(location => {
       location.classList.remove('hidden');
     })
    
//     display courts which contain said location
    renderLocationCourtList();
    
  }
  
  const renderLocationCourtList = () => {
//     clear all initially loaded buttons
    courtSelector.innerHTML = '';
    
    let uniqueLocationCourts = [];
//     grab all courts that are being shown in the current location
    let matchingRow = document.querySelectorAll('tbody tr:not(.hidden)');
    
//     loop through matching courts to find unique courts
    matchingRow.forEach(row => {
      let courtName = row.firstChild.textContent;  
      
//       if the court name is unique, push to uniqueCourts
      if(uniqueLocationCourts.includes(courtName)) {
        return;
      } else {
        uniqueLocationCourts.push(courtName);
      }
      
    })
    
    //     create button list using uniqueLocationCourts
        for(let i = 0; i < uniqueLocationCourts.length; i++) {
          const button = document.createElement("button");
          button.innerHTML = uniqueLocationCourts[i];
          button.classList.add('text-lg');
          button.setAttribute('data-button-type', 'courtBtn')
          button.addEventListener('click', showCourts)

          courtSelector.appendChild(button);
        }
    courtSelector.classList.remove('hidden');
  }
  
  const findDayOfWeek = (row) => {
    let targetCell = row;
    let dateCell = row.querySelector('td:nth-of-type(2)');
    let dateCellText = dateCell.innerHTML;
    let splitDate = dateCell.innerHTML.split(' - ');
    let cleanDateString = new Date(splitDate[0]);
    let day = cleanDateString.getDay();
    
//     0 is Sunday
   switch(day) {
     case 4: 
       targetCell.style.backgroundColor = '#f2f2f2';
       break;
     case 5: 
       targetCell.style.backgroundColor = '#b39ddb';
       break;
     case 6:
       targetCell.style.backgroundColor = '#ffb74d';
       break;
     case 0:
       targetCell.style.backgroundColor = '#fff176';
       break;
     case 1:
       targetCell.style.backgroundColor = '#7bdc8a';
       break;
     case 2:
       targetCell.style.backgroundColor = '#7bdcd8';
       break;
     case 3:
       targetCell.style.backgroundColor = '#7bb0dc';
       break;
   }
  }

  
//     valid for initial render of 1 location only
    let uniqueLocations = [];
    let uniqueCourts = [];
    const allRows = document.querySelectorAll('.court_list tbody tr');
    const courtSelector = document.getElementById('courtSelector');
    const locationSelector = document.getElementById('locationSelector');
    
  
    allRows.forEach(row => {
      findDayOfWeek(row);
      
//       tag each row with a data court and location tag
      let courtName = row.firstChild.textContent;
      let locationName = row.lastChild.textContent;
      
      row.setAttribute('data-court', courtName);
      row.setAttribute('data-location', locationName);
      
//       if the court name is unique, push to uniqueCourts
      if(!uniqueCourts.includes(courtName)) {
        uniqueCourts.push(courtName);
      } 
      
      //       if the location name is unique, push to uniqueCourts
      if(!uniqueLocations.includes(locationName)) {
        uniqueLocations.push(locationName);
      }
      
    })
  
//     create button list using uniqueCourts
    for(let i = 0; i < uniqueCourts.length; i++) {
      const button = document.createElement("button");
      button.innerHTML = uniqueCourts[i];
      button.classList.add('text-lg');
      button.setAttribute('data-button-type', 'courtBtn')
      button.addEventListener('click', showCourts)
      
      courtSelector.appendChild(button);
    }
  //     create button list using uniqueLocations
    for(let i = 0; i < uniqueLocations.length; i++) {
      const button = document.createElement("button");
      button.innerHTML = uniqueLocations[i];
      button.classList.add('font-urbanist', 'text-white', 'text-base','border-2', 'p-1','mx-1','rounded-lg');
      button.setAttribute('data-button-type', 'locationBtn')
      button.addEventListener('click', showLocations)
      
      locationSelector.appendChild(button);
    }
  
//   hide locations only if there is 1 location (All / Location 1) and show all courts
  if(locationSelector) {
    if(uniqueLocations.length <= 1) {
      locationSelector.classList.add('hidden');
      courtSelector.classList.remove('hidden');
      setIsMultipleLocations(false);
    } else {
      locationSelector.classList.remove('hidden');
      courtSelector.classList.add('hidden');
      setIsMultipleLocations(true);
    }
  }
  
  
    
  return (
    <table {...getTableProps()} className="w-full border-none border-collapse">
      <thead style={{background: "white"}}>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="text-black font-urbanist last:hidden"{...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} >
              {row.cells.map(cell => {
                return (
                  <td  className="text-sm text-black font-urbanist py-1 last:hidden" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SimpleTable;
