import React from 'react';

const ActiveRoster = ({activePlayerList, addToBench, activeToDNP, benchHandler, selectPlayer, isStatKeep, eventId, gameId}) => {
  const playerData = activePlayerList;
  
  const initialName = (name) => {
    let splitName = name.split(' ');    
    let newName = splitName[0].charAt(0).toUpperCase() + '. ' + splitName[1];
    return newName;
  }

  return (
    <>
    <div className="home_active_roster">
      { 
      (playerData) ? 
        (
          playerData.map((selectedPlayer) => {
            return (
              <div className={isStatKeep ? 'w-1/5 relative flex flex-col items-center active-player [&:not(:last-child)]:border-r border-sky-500 rounded-tr-xl': 'relative flex flex-col items-center mb-2 active-player'} key={ selectedPlayer.id } onClick={(e) => selectPlayer(e, selectedPlayer)}>
                <div className={isStatKeep ? 'w-3/4 flex flex-row justify-between items-center my-1': 'flex flex-col items-center'}>
                  <img src={ selectedPlayer.profile_img } alt={ selectedPlayer.name } style={{ width: '40px', height: '40px' }}  className="rounded-full object-cover object-top" />
                  <h2 className={isStatKeep ? "font-urbanist text-white text-sm flex items-center": "font-urbanist text-white text-base mb-1"}>
                   <span className={isStatKeep ? "text-2xl font-bold": ""}>{ selectedPlayer.j_num }</span>  
                   <span className={isStatKeep ? "hidden": ""}>{'. ' + initialName(selectedPlayer.name) }</span>
                  </h2>
                </div>
               <div className='flex flex-row gap-2 mt-1'>  
                <button className={isStatKeep ? 'hidden' : 'font-urbanist text-white text-base border-2 p-1 rounded-lg'} onClick={() => addToBench(selectedPlayer)}>Roster</button>
                {/* <button className={isStatKeep ? 'hidden': 'font-urbanist text-red-dark  text-base p-1 rounded-lg bg-red'} id="activeDNP" onClick={() => activeToDNP(selectedPlayer, selectedPlayer.j_num)}>Inactive</button> */}
                <button className={isStatKeep ? 'w-full font-urbanist text-red-dark text-base p-1 px-3 font-bold rounded-lg bg-red roster-minus': 'hidden'} onClick={() => addToBench(selectedPlayer)}>-</button>
               </div>
              </div>
            );
          })
        ) 
        : '' 
      }
    </div>
    </>
  )
}

export default ActiveRoster
