import React, { useState } from 'react';
import { playerZero } from '../utils/playerZero';

const BenchRoster = ({newBenchData, playerJnum, addToActive, addToDNP, subToActiveHandler, isStatKeep}) => {
  const playerData = newBenchData;
  const playerJnumData = playerJnum;
  const getPlayerZeroType = (playerData[0]) ? playerData[0]['homeaway'] : '';
  const [verifyPasscode, setVerifyPasscode] = useState(false);
  const [benchPlayerList, setBenchPlayerList] = useState(false);
  const handlePasscode = (passcode) => {
    let twoDigitDay = new Date().getFullYear().toString().substr(-2);
    if(passcode === 'SPP'+("0" + new Date().getDate()).slice(-2)){ setVerifyPasscode(true); }
  }
  const newPlayerData = [];
  Object.keys(playerData).forEach(key => newPlayerData.push( { ...playerData[key] , "j_num": playerJnumData[playerData[key].id]['j_num'] } ));
  // const playerZeroData = {
  //   id: playerData.id,
  //   name: "Player Zero",
  //   j_num: 0,
  //   profile_img: process.env.REACT_APP_DEFAULT_IMG_PLACEHOLDER,
  //   homeaway: getPlayerZeroType
  // }

  newPlayerData.sort((a, b) => a['j_num'] - b['j_num']);

  const initialName = (name) => {
    let splitName = name.split(' ');
   
    let newName = splitName[0].charAt(0).toUpperCase() + '. ' + splitName[1];
    return newName;
    
  }
//   console.log(playerData)
  return (
    <>
    { (verifyPasscode) ? (<button className="font-urbanist text-black border-2 p-1 rounded-lg" onClick={() => addToActive(playerZero(getPlayerZeroType), 0)}>Player Zero</button>) : ''}
    <input
      type="password"
      name="player_zero_passcode"
      className="text-black w-full"
      placeholder="Player Zero Passcode"
      className="text-base text-black"
      onChange={(passcode) => handlePasscode(passcode.target.value)}
    />
    <div className="bench_roster mt-3">
      { (newPlayerData) ? 
        (
          newPlayerData.map((playerData) => {
            let getPlayerJnum = 0;
            // Hardcode player zero id
            if(playerData.id === "11000" || playerData.id === "11001"){
              getPlayerJnum = 0;
            }else{
              getPlayerJnum = playerJnumData[playerData.id]['j_num'];
            }
            return (
              <div className="relative flex flex-col items-center bench-player" key={ playerData.id }>
                <div className="flex flex-row justify-center items-center" style={playerData.eligibility === "Ineligible" ? { borderRadius: '30px', border: '2px solid red' } : {}}>
                  <img src={ playerData.profile_img } alt={ playerData.name } className="rounded-full object-cover object-top " style={{ width: '50px', height: '50px' }} />
                  <button className={isStatKeep ? "flex items-center font-urbanist text-white text-base border-2 rounded-lg mx-2 p-2  h-7" : 'hidden'} id="benchStartPlus" onClick={(e) => addToActive(playerData, getPlayerJnum)}>+</button>
                </div>
                <h2 className="font-urbanist text-white text-base mb-1">{getPlayerJnum + '. ' + initialName(playerData.name) }</h2>
                 <div className={isStatKeep ? 'hidden' : 'flex flex-row gap-2 mt-1'}>  
                  <button className="font-urbanist text-white text-base border-2 p-1 rounded-lg" id="benchStart" onClick={() => addToActive(playerData, getPlayerJnum)}>Active</button>
                  <button className="font-urbanist text-red-dark text-base  p-1 rounded-lg bg-red" id="benchDNP" onClick={() => addToDNP(playerData, getPlayerJnum)}>Inactive</button>
                 </div>
              </div>
            );
          })
        ) 
        : '' 
      }
    </div>
    </>
  )
}

export default BenchRoster
