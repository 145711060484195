import React from 'react';

const RosterDNP = ({dnpPlayerList, dnpToBench, dnpToActive, isStatKeep}) => {
  const playerData = dnpPlayerList;
//   console.log(playerData)

  const initialName = (name) => {
    let splitName = name.split(' ');
    
    let newName = splitName[0].charAt(0).toUpperCase() + '. ' + splitName[1];
    return newName;
    
  }
  return (
    <>
    <div className="home_dnp mt-3">
      { (playerData) ? 
        (
          playerData.map((playerData) => {
            return (
              <div className="relative flex flex-col items-center mb-2" key={ playerData.id }>
                <img src={ playerData.profile_img } alt={ playerData.name } className="rounded-full object-cover object-top " style={{ width: '50px', height: '50px' }} />
                <h2 className="font-urbanist text-white text-base mb-1">{  playerData.j_num + '. ' + initialName(playerData.name) }</h2>
                <div className="flex flex-row gap-2 mt-1">
                  <button className="font-urbanist text-white text-base border-2 p-1 rounded-lg" onClick={() => dnpToActive(playerData)}>Active</button>
                  <button className="font-urbanist text-red-dark text-base p-1 rounded-lg bg-red" onClick={() => dnpToBench(playerData)}>Roster</button>
                </div>
              </div>
            );
          })
        ) 
        : '' 
      }
    </div>
    </>
  )
}

export default RosterDNP
