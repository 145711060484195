// import React, { useState } from 'react';
// import { getVar } from '../utils/customVariable';
// import { getDivisions } from '../utils/divisionConverter';
// import { siteUrl } from '../utils/siteUrl';
// import { connect } from 'react-redux';
// import { setFetchTriggerCall } from '../reducers/actions';
// import { GameScoreResource } from '../logic/endpoints/endpointResource';
// // import TeamTimeout from '../utils/teamTimeout';

// const GameScore = ({ fetchTriggerCall, ...props }) => {
//   const [homeScore, setHomeScore] = useState([]);
//   const fullURL = window.location.href;
//   const getStatURL = getVar('fb-rt-stat', fullURL);
//   const siteType = siteUrl(fullURL, 'expressJS');
//   const getGameData = (props.gameData[0]) ? props.gameData[0] : '';
//   const location = `${getStatURL}/${getGameData.event_id}/${getGameData.id}`;
  
//   GameScoreResource(getGameData, siteType, location, setHomeScore, fetchTriggerCall);

//   const handleTFReset = (team_type) => {
//     let teamFoul = document.getElementById(team_type);
//     let oppTF = '';
//     if(team_type === 'home_tf'){ oppTF =  'away_bonus'; }
//     else if(team_type === 'away_tf'){ oppTF =  'home_bonus'; }
//     let oppTeamFoul = document.getElementById(oppTF);
//     teamFoul.innerText = '0';
//     oppTeamFoul.innerText = '';
//   }
  
//   return (
//     <>
//     <div className="flex flex-row justify-evenly  w-11/12">
//       <>
//       <div className="home_score w-6/12 flex flex-row justify-evenly items-center">
//         <p className="font-urbanist text-black text-lg font-bold w-6/12 leading-none">{ (props.gameData[0]) ? getDivisions('girls', (props.gameData[0].home_team) ? props.gameData[0].home_team : '') : '' }</p>
//         <div className="flex flex-col">
//           <div className="flex flex-row justify-center items-center">
//             <p id="home_tf" className="text-black text-sm" onClick={() => handleTFReset('home_tf')}>0</p>
//             <p id="home_bonus" className="text-dark-gold text-sm p-2 font-bold"></p>
//           </div>
//           {/*<TeamTimeout />*/}
//         </div>
//         <img src={ (props.gameData[0]) ? props.gameData[0].home_logo : '' } alt={ (props.gameData[0]) ? props.gameData[0].home_logo : '' } className="w-4/12 h-10 object-contain"></img>
//         <span className="font-urbanist text-black text-[30px] font-bold">{ homeScore.home_score }</span>
//       </div>
//       <div className='away_score w-6/12 flex flex-row justify-evenly items-center'>  
//         <span className="font-urbanist text-black text-[30px] font-bold">{ homeScore.away_score }</span>
//        <img src={ (props.gameData[0]) ? props.gameData[0].away_logo : '' } alt={ (props.gameData[0]) ? props.gameData[0].away_logo : '' } className="w-4/12 h-10 object-contain"></img>
//         <p id="away_tf" className="text-red text-black text-sm" onClick={() => handleTFReset('away_tf')}>0</p>
//         <p id="away_bonus" className="text-dark-gold text-sm p-2 font-bold"></p>
//         <p className="font-urbanist text-black text-lg font-bold  w-6/12 leading-none">{ (props.gameData[0]) ? getDivisions('girls', (props.gameData[0].away_team) ? props.gameData[0].away_team : '') : '' }</p>
//       </div>
//       </>
//     </div>
//     </>
//   )
// }

// const mapStateToProps = state => ({ fetchTriggerCall: state.fetchTriggerCall });

// // Ensure setFetchTriggerCall is included in mapDispatchToProps
// const mapDispatchToProps = { setFetchTriggerCall };

// export default connect(mapStateToProps, mapDispatchToProps)(GameScore);

// // export default GameScore

import React, { useEffect, useState } from 'react';
import { realtimeDB } from '../firebaseConfig';
import { onValue, ref } from 'firebase/database';
import { getVar } from '../utils/customVariable';
import { getDivisions } from '../utils/divisionConverter';
// import TeamTimeout from '../utils/teamTimeout';

const GameScore = (props) => {
  const [playerStats, setPlayerStats] = useState([]);
  const [homeScore, setHomeScore] = useState([]);
  const fullURL = window.location.href;
  const getStatURL = getVar('fb-rt-stat', fullURL);
  // Get player stats
  useEffect(() => {
    const getGameData = (props.gameData[0]) ? props.gameData[0] : '';
    if(getGameData.event_id !== undefined){
      onValue(ref(realtimeDB), (snapshot) => {
        setPlayerStats([]);
        const userData = snapshot.val();
        if(typeof(userData['playerStats'][getStatURL][getGameData.event_id]) !== 'undefined'){
          const homePlayers = Object.values(userData['playerStats'][getStatURL][getGameData.event_id][getGameData.id]).filter(players => players.roster_type === 'home' && players.event_id === getGameData.event_id && players.game_id === getGameData.id);
          const awayPlayers = Object.values(userData['playerStats'][getStatURL][getGameData.event_id][getGameData.id]).filter(players => players.roster_type === 'away' && players.event_id === getGameData.event_id && players.game_id === getGameData.id);
          const playerHomeScore = homePlayers.reduce((accumulator, currentItem) => accumulator + currentItem.pts, 0);
          const playerAwayScore = awayPlayers.reduce((accumulator, currentItem) => accumulator + currentItem.pts, 0);
          setHomeScore({'home_score': playerHomeScore, 'away_score': playerAwayScore});
        }
      });
    }
  }, [props]);

  const handleTFReset = (team_type) => {
    let teamFoul = document.getElementById(team_type);
    let oppTF = '';
    if(team_type === 'home_tf'){ oppTF =  'away_bonus'; }
    else if(team_type === 'away_tf'){ oppTF =  'home_bonus'; }
    let oppTeamFoul = document.getElementById(oppTF);
    teamFoul.innerText = '0';
    oppTeamFoul.innerText = '';
  }
  
  return (
    <>
    <div className="flex flex-row justify-evenly  w-11/12">
      <>
      <div className="home_score w-6/12 flex flex-row justify-evenly items-center">
        <p className="font-urbanist text-black text-lg font-bold w-6/12 leading-none">{ (props.gameData[0]) ? getDivisions('girls', (props.gameData[0].home_team) ? props.gameData[0].home_team : '') : '' }</p>
        <div className="flex flex-col">
          <div className="flex flex-row justify-center items-center">
            <p id="home_tf" className="text-black text-sm" onClick={() => handleTFReset('home_tf')}>0</p>
            <p id="home_bonus" className="text-dark-gold text-sm p-2 font-bold"></p>
          </div>
          {/*<TeamTimeout />*/}
        </div>
        <img src={ (props.gameData[0]) ? props.gameData[0].home_logo : '' } className="w-4/12 h-10 object-contain"></img>
        <span className="font-urbanist text-black text-[30px] font-bold">{ homeScore.home_score }</span>
      </div>
      <div className='away_score w-6/12 flex flex-row justify-evenly items-center'>  
        <span className="font-urbanist text-black text-[30px] font-bold">{ homeScore.away_score }</span>
       <img src={ (props.gameData[0]) ? props.gameData[0].away_logo : '' } className="w-4/12 h-10 object-contain"></img>
        <p id="away_tf" className="text-red text-black text-sm" onClick={() => handleTFReset('away_tf')}>0</p>
        <p id="away_bonus" className="text-dark-gold text-sm p-2 font-bold"></p>
        <p className="font-urbanist text-black text-lg font-bold  w-6/12 leading-none">{ (props.gameData[0]) ? getDivisions('girls', (props.gameData[0].away_team) ? props.gameData[0].away_team : '') : '' }</p>
      </div>
      </>
    </div>
    </>
  )
}

export default GameScore